import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel
} from "@headlessui/react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,

} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
    PlusCircleIcon,
    PencilSquareIcon,
    TrashIcon,
    WindowIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    InboxArrowDownIcon,
    ArrowUpTrayIcon,
} from "@heroicons/react/20/solid";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

import { FileUploader } from "react-drag-drop-files";
import { InboxIcon } from "@heroicons/react/20/solid";

// CSS
import styles from "./styles.module.css";

//Importo componenti
import TablePatients from "./componets/TablePatients";

//Importo Menu
import { MenuDesktop } from "./componets/Menu_desktop";
import { MenuMobile } from "./componets/Menu_mobile";
import { MenuNavigazioneMobile } from "./componets/Menu_navigazioneMobile";
import { MenuNavigazioneDesktop } from "./componets/Menu_navigazioneDesktop";

// Tooltip
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";

//Importo Autenticazione Token
import getAuthToken from "../features/auth/axiosSetup";

//Importo Alert
import { AlertRed, AlertGreen } from "./componets/Alert";
import moment from "moment";
import axios from "axios";
import ModalCode from "./componets/ModalCode";

import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Papa from 'papaparse';

import DragAndDrop from "./upload_wizard_pazienti/DragAndDrop"
import Galleria from "./componets/Galleria"
import PowerPoint from './componets/PowerPoint';
import PresentationModale from "./componets/presentation"

function Pazienti() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const modal_ref = useRef(null);

    //Dichiaro gli State
    const [nome, setNome] = useState("");
    const [cognome, setCognome] = useState("");
    const [ruolo, setRuolo] = useState("");
    const [email, setEmail] = useState("");
    const [patients, setPatients] = useState([{}]); // Puoi cambiare il numero per pagina qui
    const [studios, setStudios] = useState([{}]); // Puoi cambiare il numero per pagina qui
    const [loadingPatients, setLoadingPatients] = useState(false); // Puoi cambiare il numero per pagina qui
    const [open, setopen] = useState(false); // Puoi cambiare il numero per pagina qui
    const [Selectedpatient, setSelectedpatient] = useState({});

    const [textGreenAlert, setTextGreenAlert] = useState("");
    const [loadingAlertGreen, setLoadingAlertGreen] = useState(false);

    const [settings, setSettings] = useState('');

    const [ModalData, setModaldata] = useState([])

    const getAllStudios = async () => {
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();

            // Configura la richiesta
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/studio/fetch_all`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                data: {
                    usernameGet: getDecryptedCookie(
                        process.env.REACT_APP_COOKIE_USERNAME,
                    ),
                },
            };

            // Effettua la richiesta con axios
            const response = await axios.request(config);

            // Controllo la risposta
            if (response.data.status === true) {
                // Setta lo state con i pazienti
                setStudios(response.data.studios);
            } else {
                // Gestisci il caso in cui la risposta abbia status false
                console.log("Errore nella risposta:", response.data.message);
                // Potresti anche gestire il caso di errore impostando un messaggio di stato o un alert
            }
        } catch (error) {
            console.log("Errore nella richiesta:", error);
            // Gestione dell"errore, ad esempio aggiornando lo stato per mostrare un messaggio all"utente
        }
    };

    //Funzione per recuperare tutta la lista degli utenti
    const getAllConnection = async () => {
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();

            // Configura la richiesta
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/patients/fetch_all`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                data: {
                    usernameGet: getDecryptedCookie(
                        process.env.REACT_APP_COOKIE_USERNAME,
                    ),
                },
            };

            // Effettua la richiesta con axios
            const response = await axios.request(config);

            // Controllo la risposta
            if (response.data.status === true) {
                // Setta lo state con i pazienti
                setPatients(response.data.patients.map((item) => ({
                    ...item,
                    show: false
                })));
                setSelectedpatient(response.data.patients[0]);

                // Set loading
                setLoadingPatients(true);
            } else {
                // Gestisci il caso in cui la risposta abbia status false
                console.log("Errore nella risposta:", response.data.message);
                // Potresti anche gestire il caso di errore impostando un messaggio di stato o un alert
            }
        } catch (error) {
            console.log("Errore nella richiesta:", error);
            // Gestione dell"errore, ad esempio aggiornando lo stato per mostrare un messaggio all"utente
        }
    };

    //Funzione per recuperare tutta la lista degli utenti
    const getSettings = async () => {
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();

            // Configura la richiesta
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/settings/get-setup.by-user`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                data: {
                    usernameGet: getDecryptedCookie(
                        process.env.REACT_APP_COOKIE_USERNAME,
                    ),
                },
            };

            // Effettua la richiesta con axios
            const response = await axios.request(config);

            // Controllo la risposta
            if (response.data.status) {
                setSettings(response.data.data)
            } else {
                // Gestisci il caso in cui la risposta abbia status false
                console.log("Errore nella risposta:", response.data.message);
                // Potresti anche gestire il caso di errore impostando un messaggio di stato o un alert
            }
        } catch (error) {
            console.log("Errore nella richiesta:", error);
            // Gestione dell"errore, ad esempio aggiornando lo stato per mostrare un messaggio all"utente
        }
    };
    
    useEffect(() => {
        getAllStudios();
        getAllConnection();
        getSettings();
    }, []);

    // Funzione per cancellare i Cookie criptati
    function removeEncryptedCookie(key) {
        // Rimuove il cookie specificato
        Cookies.remove(key);
    }

    // Funzione per riprendere i cookie salvati
    function getDecryptedCookie(key) {
        try {
            const encryptedValue = Cookies.get(key);

            if (!encryptedValue) {
                return null;
            }
            // Decriptazione
            const bytes = CryptoJS.AES.decrypt(
                encryptedValue,
                process.env.REACT_APP_COOKIE_SECRET,
            );

            if (bytes) {
                const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);

                if (decryptedValue) {
                    return decryptedValue;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    // Esegui il controllo una sola volta quando la pagina viene montata
    useEffect(() => {
        setNome(getDecryptedCookie(process.env.REACT_APP_COOKIE_FNAME));
        setCognome(getDecryptedCookie(process.env.REACT_APP_COOKIE_LNAME));
        setRuolo(getDecryptedCookie(process.env.REACT_APP_COOKIE_ROLE));
        setEmail(getDecryptedCookie(process.env.REACT_APP_COOKIE_EMAIL));
    }, []);

    const handleLogout = () => {
        //Cancello i Cookiee
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_FNAME);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_LNAME);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_ROLE);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_EMAIL);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_USERNAME);

        //Eseguo Logout
        dispatch(logout());
        navigate("/login");
    };

    const handleProfilo = () => {
        navigate("/profilo");
    };

    //Funzione per Generare l"immagine
    function generateInitialsImage(name, surname) {
        const initials = (name[0] || "") + (surname[0] || "");
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        // Imposta le dimensioni del canvas
        const size = 80; // Dimensione dell"immagine
        canvas.width = size;
        canvas.height = size;

        // Imposta il colore di sfondo
        context.fillStyle = "#5930B6"; // Colore di sfondo (puoi personalizzarlo)
        context.fillRect(0, 0, size, size);

        // Imposta le proprietà del testo
        context.fillStyle = "#fff"; // Colore del testo
        context.font = "36px Urbanist, sans-serif"; // Font e dimensione del testo

        context.textAlign = "center";
        context.textBaseline = "middle";

        // Disegna le iniziali al centro del canvas
        context.fillText(initials, size / 2, size / 2);

        // Ritorna l"URL dell"immagine generata
        return canvas.toDataURL("image/png");
    }

    // Genera l"immagine con le iniziali dell"utente
    const initialsImageUrl = generateInitialsImage(nome, cognome);

    const GetAnno = () => {
        return new Date().getFullYear();
    };

    function getStudioNameById( id) {
        // Trova l'oggetto con l'_id corrispondente
        const foundObject = studios.find(item => item._id === id);
        // Restituisci il nome se trovato, altrimenti null
        return foundObject ? foundObject.name : null;
    }

    useEffect(() => {
        if (Selectedpatient.src_images.length > 0) {
            const url = settings.type === "SFTP" 
                ? `${settings.url_pubblico}/${settings.folderName}/` 
                : settings.url_pubblico;
    
            const types = ['Fronte', 'Sinistra', 'Mandibolare', 'Mascellare', 'Destra'];
    
            const data = types.map((type) => {
                const Images = Selectedpatient.src_images.filter(image => image.type === type);
    
                return {
                    url: url,
                    type: type,
                    indexToShow: 0,
                    data: Images,
                    get urlToShow() {
                        return `${this.url}/compressioni/${this.data[this.indexToShow]?.src || ""}`;
                    },
                    get idShowen() {
                        return this.data[this.indexToShow]?._id;
                    },
                    showNextImage() {
                        if (this.indexToShow < this.data.length - 1) {
                            this.indexToShow++;
                        }
                    },
                    showPreviousImage() {
                        if (this.indexToShow > 0) {
                            this.indexToShow--;
                        }
                    }
                };
            });
    
            setModaldata(data);
        }
    }, [Selectedpatient, settings]);
    

    return (
        <>
            <div className="flex flex-col min-h-screen bg-gray-100">
                <Disclosure as="nav" className="bg-white shadow-sm">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                            <div className="flex">
                                <div className="flex flex-shrink-0 items-center">
                                    <img
                                        alt="Your Company"
                                        src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                                        className="block h-8 w-auto lg:hidden"
                                    />
                                    <img
                                        alt="Your Company"
                                        src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                                        className="hidden h-8 w-auto lg:block"
                                    />
                                </div>
                                <MenuNavigazioneDesktop
                                    ruolo={ruolo}
                                    navigate={navigate}
                                />
                            </div>

                            <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                {/* Profile dropdown */}
                                <MenuDesktop
                                    nome={nome}
                                    cognome={cognome}
                                    ruolo={ruolo}
                                    email={email}
                                    initialsImageUrl={initialsImageUrl}
                                    handleLogout={handleLogout}
                                    handleProfilo={handleProfilo}
                                />
                            </div>

                            {/* Mobile Section */}
                            <div className="-mr-2 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    <Bars3Icon
                                        aria-hidden="true"
                                        className="block h-6 w-6 group-data-[open]:hidden"
                                    />
                                    <XMarkIcon
                                        aria-hidden="true"
                                        className="hidden h-6 w-6 group-data-[open]:block"
                                    />
                                </DisclosureButton>
                            </div>
                        </div>
                    </div>

                    {/* Mobile  */}
                    <DisclosurePanel className="sm:hidden">
                        <MenuNavigazioneMobile
                            ruolo={ruolo}
                            navigate={navigate}
                        />
                        <MenuMobile
                            nome={nome}
                            cognome={cognome}
                            ruolo={ruolo}
                            email={email}
                            initialsImageUrl={initialsImageUrl}
                            handleLogout={handleLogout}
                            handleProfilo={handleProfilo}
                        />
                    </DisclosurePanel>
                </Disclosure>



                    <div className="grow h-auto">
                        <header >
                            <div className="mx-5 xl:mx-20 max-w-full bg-white shadow sm:rounded-lg my-10 overflow-hidden h-full">

                                <div className="grid grid-cols-2 items-center pt-5 pb-4 px-4 sm:px-6 lg:px-8">
                                    <div>
                                        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                                            Presentazione
                                        </h1>
                                        <h3 className="text-base font-semibold leading-7 text-gray-900">
                                            Informazioni
                                        </h3>
                                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                                            Sezione dedicata alla gestione degli pazienti.
                                        </p>
                                    </div>
                                </div>

                                <div className="flex" >

                                    {/* Sidebar per desktop */}
                                    <div className="flex w-64 flex-col  text-white  ">
                                    <div className="text-lg font-bold p-4 bg-indigo-900">Lista Pazineti</div>

                                    </div>

                                    {/* Contenuto principale */}
                                    <div className="flex-1 bg-gray-100">

                                        <div className="flex justify-between items-center p-4 ">
                                            <div className="flex flex-row items-center">
                                                {/*<h2 className="text-lg leading-6 font-medium text-gray-900">
                                                    Gestione Pazienti
                                                </h2>*/}

                                                    <button
                                                            type="button"
                                                            onClick={()=>{
                                                                setopen(true)
                                                            }}
                                                            className="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                        >
                                                            Button text
                                                        </button>

                                                    <PowerPoint />
                                                    <PresentationModale isOpen={open} onClose={setopen} ModalData={ModalData}  />

                                            </div>
                                            <div className="flex flex-row items-center">
                                                <div className=" relative">
                                                    <select
                                                        id="location"
                                                        name="location"
                                                        value={null}
                                                        className={`w-full bg-white py-1.5 pl-8 pr-4 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm transition border border-gray-300`}
                                                    >
                                                        <option value="" disabled className="text-gray-400">
                                                            Seleziona un'opzione
                                                        </option>

                                                        <option
                                                            value={'1'}
                                                            className="text-gray-900 text-sm bg-white hover:bg-gray-100"
                                                        >
                                                            Opzione 1
                                                        </option>
                                                    </select>
                                                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-4 w-4"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M5.3 8.7a1 1 0 011.4-1.4l5.3 5.3a1 1 0 01-1.4 1.4L5.3 8.7z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div className="relative mr-6">
                                                    <input
                                                        type="text"
                                                        className={`ml-2 border bg-white py-1.5 pl-8 pr-4 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm transition`}
                                                        value={null}
                                                        placeholder="Cerca"
                                                    />
                                                    <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-400">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-4 w-4"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M12.9 14.32a8 8 0 111.41-1.41l4.3 4.29a1 1 0 01-1.42 1.42l-4.29-4.3zM14 8a6 6 0 11-12 0 6 6 0 0112 0z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="">
                                    <div>
                                        <div className="px-4 sm:px-0"></div>
                                        <div className="w-full">
                                            {loadingAlertGreen ? (
                                                <AlertGreen testo={textGreenAlert} />
                                            ) : null}
                                        </div>
                                        {loadingPatients ?
                                            <Galleria patients={patients} settings={settings} Selectedpatient={Selectedpatient} setSelectedpatient={setSelectedpatient} getStudioNameById={getStudioNameById} getAllConnection={getAllConnection} />
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </header>
                    </div>


                <footer className="bg-white">
                    <div className="mx-auto px-6 sm:py-3 md:py-3 xl:py-5 items-center">
                        <div className="mt-4 md:order-1 md:mt-0">
                            <p className="text-center text-xs leading-5 text-gray-500">
                                {GetAnno()} © Centro del Sorriso, sviluppato
                                con il ❤️ da Tribus Informatica Srl
                            </p>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default Pazienti;