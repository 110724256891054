import React, { useState, useEffect, useRef } from "react";
import PptxGenJS from "pptxgenjs";
import getAuthToken from "../../features/auth/axiosSetup";
import axios from "axios";

const PowerPointGenerator = () => {

  const [image , setImage] = useState()


  const getImage = async ()=>{
    try {
      // Ottieni il token in modo asincrono
      const token = await getAuthToken();

      // Configura la richiesta
      let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_URL_API}/patients/Get-img`,
          headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${token}`, // Usa il token ottenuto
          },
      };

      // Effettua la richiesta con axios
      const response = await axios.request(config);

      //Controllo la risposta e conseguneza setto gli state
      if (response.data.success) {
          //Nessuna configurazione trovata stampo badge e diabilito configurazione
          setImage(response.data.img)
      }
    } catch (error) {
        console.log("Errore nella richiesta:", error);
    }
  }

  const generatePresentation = () => {
    const pptx = new PptxGenJS();

    // Slide 1: Immagini (5 sezioni)
    const slide1 = pptx.addSlide();
    slide1.addText("Caso Clinico - Slide 1", {
      x: 0.5,
      y: 0.2,
      fontSize: 20,
      bold: true,
      color: "0033CC",
    });

    // Aggiungi 5 immagini segnaposto
    slide1.addText("Immagine 1", { x: 0.5, y: 1, w: 2.5, h: 2 });
    slide1.addText("Immagine 2", { x: 3.5, y: 1, w: 2.5, h: 2 });
    slide1.addText("Immagine 3", { x: 6.5, y: 1, w: 2.5, h: 2 });
    slide1.addText("Immagine 4", { x: 0.5, y: 3.5, w: 4, h: 2.5 });
    slide1.addText("Immagine 5", { x: 5, y: 3.5, w: 4, h: 2.5 });

    // Slide 2: Immagini (layout simile)
    const slide2 = pptx.addSlide();


    // Aggiungi altre 5 immagini segnaposto

    const slideWidth = 10; // Larghezza della slide (in pollici, standard di PptxGenJS)
    const margin = slideWidth * 0.01; // Calcolo del margine del 5%

    // Calcolo della larghezza di ciascuna immagine
    const imageWidth = (slideWidth - 2 * margin) / 3;

    // Posizioni x delle immagini
    const positionsX = [
    0, // Prima immagine
    margin + imageWidth, // Seconda immagine
    2 * margin + 2 * imageWidth, // Terza immagine
    ];

    const marginBottomImage = slideWidth * 0.02; // Calcolo del margine del 5%
    const imageWidthBottomImages = (slideWidth - 3 * marginBottomImage) / 2;

    const positionsXBottomImage = [
        marginBottomImage, // Prima immagine
        (marginBottomImage * 2) + imageWidthBottomImages, // Seconda immagine
    ];


    console.log(image, '66666666')
    const imageBase64 = `data:image/jpeg;base64,${image.toString("base64")}`; 
    slide2.addImage({ data: imageBase64, x: positionsX[0], y: 0.2, w: imageWidth, h: 2 }).addImage({
        data: imageBase64,
        x: positionsX[0],
        y: 0.2 + 2, // Posiziona la copia sotto
        w: imageWidth,
        h: 2, // Puoi regolare l'altezza del riflesso (esempio: metà altezza dell'immagine originale)
        flipV: true,
        sizing: {
            type: 'crop',
            w: 10,
            h: 0.3,
        },
        shadow: {
            type: "inner",
            opacity: 0.5, 
            offset: 0,
            color: "#ffffff"
        }
    });
    slide2.addImage({ data: imageBase64, x: positionsX[1], y: 0.2, w: imageWidth, h: 2 });
    slide2.addImage({ data: imageBase64, x: positionsX[2], y: 0.2, w: imageWidth, h: 2 });
    slide2.addImage({data: imageBase64,  x: positionsXBottomImage[0], y: 2.5, w: imageWidthBottomImages, h: 2.5 });
    slide2.addImage({data: imageBase64,  x: positionsXBottomImage[1], y: 2.5, w: imageWidthBottomImages, h: 2.5 });

    // Salva il file
    pptx.writeFile({ fileName: "Caso_Clinico_Slides.pptx" });
  };

  useEffect(()=>{
    getImage()
  }, [])

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Generatore di PowerPoint - Caso Clinico</h1>
      <button
        onClick={generatePresentation}
        style={{
          padding: "10px 20px",
          fontSize: "16px",
          color: "#fff",
          backgroundColor: "#007BFF",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Genera PowerPoint
      </button>
    </div>
  );
};

export default PowerPointGenerator;
