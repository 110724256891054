// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.styles_border__fIkRb {
    border: 1px solid rgb(255 255 255 / 0.1);
}

.styles_border_light__MvKOQ {
    border-width: 1px;
    border-color: rgb(233 237 246);
}

.styles_activeColor__EJLsf {
    color: #B59CEB
}

.styles_hr_dark__Hgncx {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 3px solid #252729;
}

.styles_hr_light__QcM-8 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 3px solid #B59CEB;
}

.styles_text_alert__XsXyq {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.styles_text_alert_bold__91FMw {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.styles_grab_container__CeFUH {
    cursor: grab;
}

.styles_grab_container__CeFUH:active {
    cursor: grabbing;
}`, "",{"version":3,"sources":["webpack://./src/pages/upload_wizard_pazienti/styles.module.css"],"names":[],"mappings":";AACA;IACI,wCAAwC;AAC5C;;AAEA;IACI,iBAAiB;IACjB,8BAA8B;AAClC;;AAEA;IACI;AACJ;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;IACT,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;IACT,6BAA6B;AACjC;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["\n.border {\n    border: 1px solid rgb(255 255 255 / 0.1);\n}\n\n.border_light {\n    border-width: 1px;\n    border-color: rgb(233 237 246);\n}\n\n.activeColor {\n    color: #B59CEB\n}\n\n.hr_dark {\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n    border: 0;\n    border-top: 3px solid #252729;\n}\n\n.hr_light {\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n    border: 0;\n    border-top: 3px solid #B59CEB;\n}\n\n.text_alert {\n    font-family: 'Inter', sans-serif;\n    font-weight: 500;\n}\n\n.text_alert_bold {\n    font-family: 'Inter', sans-serif;\n    font-weight: 700;\n}\n\n.grab_container {\n    cursor: grab;\n}\n\n.grab_container:active {\n    cursor: grabbing;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border": `styles_border__fIkRb`,
	"border_light": `styles_border_light__MvKOQ`,
	"activeColor": `styles_activeColor__EJLsf`,
	"hr_dark": `styles_hr_dark__Hgncx`,
	"hr_light": `styles_hr_light__QcM-8`,
	"text_alert": `styles_text_alert__XsXyq`,
	"text_alert_bold": `styles_text_alert_bold__91FMw`,
	"grab_container": `styles_grab_container__CeFUH`
};
export default ___CSS_LOADER_EXPORT___;
