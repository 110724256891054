import React, { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

const Modal = ({ isOpen, onClose , ModalData }) => {
  const [currentIndexTop, setCurrentIndexTop] = useState(0); // Indice per la riga superiore
  const [currentIndexBottom, setCurrentIndexBottom] = useState(0); // Indice per la riga inferiore
  const images = [
    'https://sftp.centrodelsorriso.tribusinformatica.it/fotografie//compressioni/Mario_Rossi/2025_01_23/1737707712742_b6znngq2_2023_10_19__5.jpg',
    'https://sftp.centrodelsorriso.tribusinformatica.it/fotografie//compressioni/Mario_Rossi/2025_01_23/1737707712742_b6znngq2_2023_10_19__5.jpg',
    'https://sftp.centrodelsorriso.tribusinformatica.it/fotografie//compressioni/Mario_Rossi/2025_01_23/1737707712742_b6znngq2_2023_10_19__5.jpg',
    'https://sftp.centrodelsorriso.tribusinformatica.it/fotografie//compressioni/Mario_Rossi/2025_01_23/1737707712742_b6znngq2_2023_10_19__5.jpg',
    'https://sftp.centrodelsorriso.tribusinformatica.it/fotografie//compressioni/Mario_Rossi/2025_01_23/1737707712742_b6znngq2_2023_10_19__5.jpg'
  ];

  console.log(ModalData, '77777777777777777lllllllllllllljjjjjjjjjjjjjjj777777777777777777')

  // Funzione per navigare tra le immagini della riga superiore
  const handlePrevTop = () => {
    setCurrentIndexTop((prevIndex) => (prevIndex - 1 + 3) % 3); // Solo 3 immagini nella riga superiore
  };

  const handleNextTop = () => {
    setCurrentIndexTop((prevIndex) => (prevIndex + 1) % 3);
  };

  // Funzione per navigare tra le immagini della riga inferiore
  const handlePrevBottom = () => {
    setCurrentIndexBottom((prevIndex) => (prevIndex - 1 + 2) % 2); // Solo 2 immagini nella riga inferiore
  };

  const handleNextBottom = () => {
    setCurrentIndexBottom((prevIndex) => (prevIndex + 1) % 2);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 ">
      <div className="bg-white  h-11/12 rounded-lg flex flex-col items-center justify-center p-5 relative">
        <button 
          className="absolute top-4 right-4 text-black text-2xl"
          onClick={()=> onClose(false)}
        >
          X
        </button>
        <h2 className='pb-4'>
          Seleziona immagini per lo slide
        </h2>
        <div className="flex flex-col justify-center items-center h-full w-full">
          {/* Riga superiore: 3 immagini */}
          <div className="flex gap-6 justify-center mb-8">
            {images.slice(0, 3).map((image, index) => (<>
              <div key={index} className="relative w-1/3"> {/* Modificato da w-1/3 a w-1/4 */}

              <div className="relative w-full mb-2">
                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-sm text-gray-500">Continue</span>
                </div>
              </div>
              
              <div className='flex justify-between gap-3'>


                  <div className="h-auto flex items-center">
                    <button
                      className="w-16 h-16 flex justify-center items-center bg-blue-100 p-3 rounded-full text-sm font-semibold"
                    >
                          <ChevronLeftIcon  aria-hidden="true" className=" w-full h-full text-[#c315a2] " />
                    </button>
                  </div>
                      <img 
                          src={image} 
                          alt={`image ${index}`} 
                          className="w-[25em] h-auto  rounded-lg shadow-lg" 
                      />
                  <div className="h-auto flex items-center">
                    <button
                      className="w-16 h-16 flex justify-center items-center bg-blue-100 p-3 rounded-full text-sm font-semibold"
                    >
                      <ChevronRightIcon aria-hidden="true" className="w-full h-full text-[#c315a2]" />
                    </button>
                  </div>
              </div>
              </div>
              {index !== images.slice(0, 3).length-1 ?
              <div className="relative flex items-center">
                  <div aria-hidden="true" className="absolute inset-y-0 left-1/2 flex items-center">
                  <div className="h-full border-l border-gray-300"></div>
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-sm text-gray-500 transform -rotate-90">
                    
                  </span>
                </div>
              </div>
              :
              null}
              </>))}
          </div>
          {/* Riga inferiore: 2 immagini */}

          <div className="flex gap-4 justify-center">
            {images.slice(3, 5).map((image, index) => (<>

              <div key={index} className="relative  w-1/2"> {/* Modificato da w-1/2 a w-1/3 */}
              
              <div className="relative w-full mb-2">
                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-sm text-gray-500">Continue</span>
                </div>
              </div>

              <div className='flex justify-between gap-3'>

                  <div className="h-auto flex items-center  ">
                      <button
                        className="w-16 h-16 flex justify-center items-center bg-blue-100 p-3 rounded-full text-sm font-semibold"
                      >
                            <ChevronLeftIcon  aria-hidden="true" className=" w-full h-full text-[#c315a2] " />
                      </button>
                  </div>

                    <img 
                      src={image} 
                      alt={`image ${index + 3}`} 
                      className="w-[30em] h-auto object-contain rounded-lg shadow-lg" 
                    />

                  <div className="h-auto flex items-center">
                    <button
                      className="w-16 h-16 flex justify-center items-center bg-blue-100 p-3 rounded-full text-sm font-semibold"
                    >
                      <ChevronRightIcon aria-hidden="true" className="w-full h-full text-[#c315a2]" />
                    </button>
                  </div>

              </div>




              </div>
              {index !== images.slice(3, 5).length-1 ?
              <div className="relative flex items-center">
                  <div aria-hidden="true" className="absolute inset-y-0 left-1/2 flex items-center">
                  <div className="h-full border-l border-gray-300"></div>
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-sm text-gray-500 transform -rotate-90">
                    
                  </span>
                </div>
              </div>
              :
              null}

              </>))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
