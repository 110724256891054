import React, { useState, useEffect, useRef } from "react";
import { MuuriComponent, useGrid, useRefresh, useDrag , useDraggable} from "muuri-react";
import { generateItems, useSend, getDimensions, getOptions } from "./utils";
import { Demo, Main, Dashboard } from "./components";
import "./style.css";
import getAuthToken from "../../features/auth/axiosSetup";
import axios from "axios";
import { UsersIcon } from "@heroicons/react/24/outline";
import Drawer from "../componets/Drawer";


const App = ({ Selectedpatient, settings , patients , getAllConnection }) => {
  const [data, setData] = useState([]);
  const [patient, setPatient] = useState({});
  const [onSendData, setOnSendData] = useState({});
  const [startDrag, setStartDrag] = useState(false);
  const [imageToMouve, setimageToMouve] = useState(false);
  const [open, setOpen] = useState(false);

  // Group images by creation date
  const groupByCreationDate = (array) => {
    return array.reduce((acc, item) => {
      const date = item.creation_date.split("T")[0]; // Extract the date (YYYY-MM-DD)
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
  };

  // On initial load, group data
  useEffect(() => {
    setPatient(Selectedpatient)
    let groupedData = [];
    const dateGroup = groupByCreationDate(Selectedpatient.src_images);

    let id = 0

    for (const key in dateGroup) {
      id++


      const items = dateGroup[key].map((item) => ({
        id: item._id,
        content: item.path,
        group: key, // Store the group (date)
        order: item.order,
      })).sort((a, b) => a.order - b.order);
      groupedData.push({
        id: id,
        title: key,
        items: items,
      });
    }

    setData(groupedData);
  }, [Selectedpatient]);

  useEffect(() => {
    setPatient(Selectedpatient)
    let groupedData = [];
    const dateGroup = groupByCreationDate(Selectedpatient.src_images);

    let id = 0

    for (const key in dateGroup) {
      id++


      const items = dateGroup[key].map((item) => ({
        id: item._id,
        content: item.path,
        group: key, // Store the group (date)
        order: item.order,
      })).sort((a, b) => a.order - b.order);
      groupedData.push({
        id: id,
        title: key,
        items: items,
      });
    }

    setData(groupedData);
  }, [Selectedpatient]);

  useEffect(() => {
    if(onSendData?.status){
      const {updatedItems, key, fromId, toId , status} = onSendData;
      SendeAndOrderImg(updatedItems, key, fromId, toId)

    }
  }, [onSendData]);

  const scrollContainerRef = useRef(null);

  const handleDragStart = (item, event) => {

    const destinationGrid = item.getGrid();
    const prova = destinationGrid.getItems()

    // Puoi aggiungere logica per evidenziare o modificare lo stato di un elemento
  };
  
  const handleDragMove = (item, event) => {

    // Puoi aggiornare la posizione o eseguire calcoli mentre l'item viene trascinato
  };
  
  const handleDragEnd = (item, event, groupId) => {
    const draggedElement = item.getElement(); // Elemento DOM spostato
    const destinationGrid = item.getGrid(); // Griglia di destinazione
    const destinationGroupId = groupId; // ID del gruppo di destinazione
    const prova = destinationGrid.getItems()

    // Ottieni il nuovo ordine degli elementi nella griglia di destinazione
    const orderedItems = destinationGrid.getItems().map((gridItem) => {
      const element = gridItem.getElement();
      const id = element.id; // Ottieni l'ID dal DOM
      return id;
    });
  
    // Aggiorna i dati per il gruppo modificato
    setData((prevData) => {
      // Trova il gruppo che deve essere aggiornato
      return prevData.map((group) => {
        if (group.id === destinationGroupId) {
          // Aggiorna gli elementi del gruppo con il nuovo ordine
          const reorderedItems = orderedItems.map((id) => {
            // Trova il contenuto originale corrispondente all'ID
            return group.items.find((item) => item.id === id);
          });

          OrgerImages(reorderedItems)

          return {
            ...group,
            items: reorderedItems,
          };
        }
        return group;
      });
    });

    setStartDrag(false);
  
  };

  const OrgerImages = async (update_fields) => {

    try {
      // Ottieni il token in modo asincrono
      const token = await getAuthToken();

      // Configura la richiesta
      let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_URL_API}/patients/order-img`,
          headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${token}`, // Usa il token ottenuto
          },
          data: { 
            patient_id: Selectedpatient,
            update_fields: update_fields
          }
      };

      // Effettua la richiesta con axios
      const response = await axios.request(config);

      //Controllo la risposta e conseguneza setto gli state
      if (response.data.status) {
          //Nessuna configurazione trovata stampo badge e diabilito configurazione

      }
  } catch (error) {
      console.log("Errore nella richiesta:", error);
  }

  }

  const SendeAndOrderImg = async (update_groups, key, fromId, toId)=>{
    try {
      // Ottieni il token in modo asincrono
      const token = await getAuthToken();

      // Configura la richiesta
      let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_URL_API}/patients/send-order-img`,
          headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${token}`, // Usa il token ottenuto
          },
          data: { 
            patient_id: patient,
            update_groups: update_groups,
            key, fromId, toId
          }
      };

      // Effettua la richiesta con axios
      const response = await axios.request(config);

      //Controllo la risposta e conseguneza setto gli state
      if (response.data.success) {
          //Nessuna configurazione trovata stampo badge e diabilito configurazione
          setOnSendData({});
          const DatatoREOrder = data.filter((item)=>item.id === fromId || item.id === toId);

          DatatoREOrder.forEach((data)=>{
            console.log(data.items)
            OrgerImages(data.items);
          })

      }
    } catch (error) {
        console.log("Errore nella richiesta:", error);
    }
  }
  
  const onSend = useSend(setData , setOnSendData);

  // Effect for mouse scroll behavior
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleMouseMove = (event) => {
      if (!scrollContainer) return;

      const { top, bottom, left, right } = scrollContainer.getBoundingClientRect();
      const mouseX = event.clientX;
      const mouseY = event.clientY;

      const baseScrollSpeed = 10;
      const maxScrollSpeed = 50;
      const minDistance = 20;

      const calculateScrollSpeed = (distance) => {
        return Math.min(maxScrollSpeed, baseScrollSpeed + (minDistance - distance) * 0.75);
      };

      if (mouseY <= top + minDistance) {
        const speed = calculateScrollSpeed(top + minDistance - mouseY);
        scrollContainer.scrollTop -= speed;
      } else if (mouseY >= bottom - minDistance) {
        const speed = calculateScrollSpeed(mouseY - (bottom - minDistance));
        scrollContainer.scrollTop += speed;
      }

      if (mouseX <= left + minDistance) {
        const speed = calculateScrollSpeed(left + minDistance - mouseX);
        scrollContainer.scrollLeft -= speed;
      } else if (mouseX >= right - minDistance) {
        const speed = calculateScrollSpeed(mouseX - (right - minDistance));
        scrollContainer.scrollLeft += speed;
      }
    };

    scrollContainer.addEventListener("mousemove", handleMouseMove);

    return () => {
      scrollContainer.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  // Map the data to dynamically populate the Muuri dashboard
  return (<>
    <Demo>
      <Main>
        <div className="max-h-[60vh] overflow-y-auto p-4 bg-gray-50 shadow-md" ref={scrollContainerRef}>
          {data.map((group) => (
            <div className="card mb-5" key={group.id}>
              <h2 className="text-xl font-semibold mb-4">Dashboard {group.title}</h2>
              <Dashboard>
                <MuuriComponent
                  id={group.id}
                  onSend={onSend}
                  {...getOptions(null, null, "move", scrollContainerRef , startDrag)}
                  onDragStart={(item, event) => handleDragStart(item, event)}
                  onDragMove={(item, event) => handleDragMove(item, event)}
                  onDragEnd={(item, event) => {
                    handleDragEnd(item, event, group.id);
                  }}
                >
                  {/* Render the items for the current group */}
                  {group.items.map((item) => (
                    <Item key={item.id} idItem={item.id} content={item.content} group={group.id} settings={settings} setOpen={setOpen} setStartDrag={setStartDrag} setimageToMouve={setimageToMouve} />
                  ))}
                  
                </MuuriComponent>
              </Dashboard>
            </div>
          ))}
        </div>
      </Main>
    </Demo>

    <Drawer open={open} setOpen={setOpen} Selectedpatient={patient} imageToMouve={imageToMouve} patients={patients} getAllConnection={getAllConnection}  />

    </>);
};

const Item = React.memo(({ idItem,  content, group , settings , setOpen , setStartDrag , setimageToMouve }) => {
  const url = settings.type === "SFTP" ? settings.url_pubblico + "/fotografie/" : settings.url_pubblico;

  const { grid, id } = useGrid();

  const isDragging = useDrag();
 
  useRefresh([id]);

  const style = getDimensions(grid, id, isDragging);

  return (<>
        <div className={`${id}-item relative`} data-id={idItem} id={idItem} style={style} 
        onClick={(e)=>{if(e.target.id === idItem){setStartDrag(true)} }}
        >
            <div className="item-content relative" id={idItem}>
              <img
                alt={`item-${group}`}
                src={`${url}/compressioni/${content}`}
                loading="lazy"
                id={idItem}
                className="content-img object-cover absolute bottom-0 left-0 rounded-md overflow-hidden outline-dashed outline-1 outline-offset-8 overflow-hidden"
              />

            </div>

            <button
                className="absolute bottom-0 right-0 rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 z-50 "
                onClick={(e) =>  {  console.log(e); setimageToMouve({
                  id: idItem,
                  url: `${url}/compressioni/${content}`,
                }); setOpen(prev => !prev)}} // Aggiungi la tua logica per il click
              >
                <UsersIcon className="h-6 w-6 " />
            </button>
        </div>



        </>);
});

export default App;
