import { useState, useEffect } from "react";

const ThreeStateCheckbox = ({checkboxState, setCheckboxState}) => {
    // Gestione dello stato del checkbox (0 = neutro, 1 = checked, -1 = unchecked)


    // Aggiorna la proprietà `indeterminate` del checkbox
    useEffect(() => {
        const checkboxElement = document.getElementById("three-state-checkbox");
        if (checkboxElement) {
            checkboxElement.indeterminate = checkboxState === "notApprove"; // Stato neutro
        }
    }, [checkboxState]);

    // Funzione per ciclare tra gli stati
    const toggleCheckbox = () => {
        setCheckboxState((prev) => {
            if (prev === "approve") return "toBeEvaluated"; // Da checked a unchecked
            if (prev === "toBeEvaluated") return "notApprove"; // Da unchecked a neutro
            return "approve"; // Da neutro a checked
        });
    };

    return (
        <div className="">
            <input
                id="three-state-checkbox"
                type="checkbox"
                checked={checkboxState === "approve"} // Checked solo se stato è approve
                className={`h-5 w-5 rounded border-gray-300 focus:ring-2 focus:ring-offset-2 ${
                    checkboxState === "approve"
                        ? "text-green-600 focus:ring-green-500" // Verde se checked
                        : checkboxState === "notApprove"
                        ? "text-red-600 focus:ring-red-500" // Rosso se unchecked
                        : "text-gray-400 focus:ring-gray-300" // Neutro
                }`}
                onClick={toggleCheckbox} // Cicla gli stati al click
                readOnly // Impostato come readOnly per evitare conflitti con checked
            />
        </div>
    );
};

export default ThreeStateCheckbox;
