import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel
} from "@headlessui/react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
    PlusCircleIcon,
    PencilSquareIcon,
    TrashIcon,
    WindowIcon,
    ChevronRightIcon,
    ChevronDownIcon,
} from "@heroicons/react/20/solid";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

// CSS
import styles from "./styles.module.css";

//Importo componenti
import TablePatients from "./componets/TablePatients";
import Table from "./componets/Table";


//Importo Menu
import { MenuDesktop } from "./componets/Menu_desktop";
import { MenuMobile } from "./componets/Menu_mobile";
import { MenuNavigazioneMobile } from "./componets/Menu_navigazioneMobile";
import { MenuNavigazioneDesktop } from "./componets/Menu_navigazioneDesktop";

// Tooltip
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";

//Importo Autenticazione Token
import getAuthToken from "../features/auth/axiosSetup";

//Importo Alert
import { AlertRed, AlertGreen } from "./componets/Alert";
import moment from "moment";
import axios from "axios";
import ModalCode from "./componets/ModalCode";

function Studi() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const modal_ref = useRef(null);

    //Dichiaro gli State
    const [nome, setNome] = useState("");
    const [cognome, setCognome] = useState("");
    const [ruolo, setRuolo] = useState("");
    const [email, setEmail] = useState("");
    const [id_to_update, setIdToUpdate] = useState("");
    const [id_to_delete, setIdToDelete] = useState("");
    const [search, setSearch] = useState("");
    const [totalPage, setTotalPage] = useState(0);
    const [code, setCode] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [patients, setPatients] = useState([]); // Puoi cambiare il numero per pagina qui
    const [loadingPatients, setLoadingPatients] = useState(false); // Puoi cambiare il numero per pagina 
    const [open, setOpen] = useState(false);
    const [openModalCode, setOpenModalCode] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [edit, setEdit] = useState(false);
    const [textRedAlert, setTextRedAlert] = useState("");
    const [textGreenAlert, setTextGreenAlert] = useState("");
    const [loadingAlertRed, setLoadingAlertRed] = useState(false);
    const [loadingAlertGreen, setLoadingAlertGreen] = useState(false);
    const [show_extra_fields, setShowExtraFields] = useState(false);

    const itemsPerPage = 5;

    //State per Aggiunta Utente
    const [data, setData] = useState({});

    //Funzione per recuperare tutta la lista degli utenti
    const getAllConnection = async () => {
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();

            // Configura la richiesta
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/studio/fetch`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                params:{
                    search:search,
                    page:currentPage,
                    limit:10 
                }
            };

            // Effettua la richiesta con axios
            const response = await axios.request(config);

            // Controllo la risposta
            if (response.data.status === true) {
                // Setta lo state con i Studi
                setPatients(response.data.studios);
                setTotalPage(response.data.totalPages)
                // Set loading
                setLoadingPatients(true);
            } else {
                // Gestisci il caso in cui la risposta abbia status false
                console.log("Errore nella risposta:", response.data.message);
                // Potresti anche gestire il caso di errore impostando un messaggio di stato o un alert
            }
        } catch (error) {
            console.log("Errore nella richiesta:", error);
            // Gestione dell"errore, ad esempio aggiornando lo stato per mostrare un messaggio all"utente
        }
    };

    useEffect(() => {
        getAllConnection();
    }, [search, currentPage ]);

    useEffect(() => {
        if (openForm && textRedAlert && modal_ref.current) {
            // Scroll to the top of the modal content when it opens
            modal_ref.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [openForm, textRedAlert]);

    const handleChangeData = (e, key) => {
        const upd_data = { ...data };

        if(key === "gender") {
            upd_data[key] = e;
        } else if(key === "birthdate") {
            const formatted_birthdate = moment(e.target.value).format();
            upd_data[key] = formatted_birthdate;
        } else if(key === "phone") {
            const phone_number = e.target.value;
            // Regex per controllare un numero di telefono italiano
            const phone_regex =  /^[+0-9]*$/;
    
            if (phone_regex.test(phone_number)) {
                upd_data[key] = phone_number; // Salva solo se il numero è valido
            }
        } else if(key === "type_code") {
            const type_code = e;
    
            if (type_code === "manual") {
                upd_data["is_manual"] = true;
            } else {
                upd_data["is_manual"] = false;
            }
            upd_data["code"] = "";
        } else {
            upd_data[key] = key !== "email" ? 
                capitalizeFirstLetter(e.target.value) :
                e.target.value;
        }
        
        setData(upd_data);
    }

    const capitalizeFirstLetter = (str) => {
        if (!str) return str; // Verifica che la stringa non sia vuota
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    // Logica per dividere `people` in pagine
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Cambia la pagina
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Esempio di navigazione pagina
    const nextPage = () =>
        setCurrentPage((prev) => (prev < totalPage ? prev + 1 : prev));
    const prevPage = () =>
        setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));

    // Funzione per cancellare i Cookie criptati
    function removeEncryptedCookie(key) {
        // Rimuove il cookie specificato
        Cookies.remove(key);
    }

    // Funzione per riprendere i cookie salvati
    function getDecryptedCookie(key) {
        try {
            const encryptedValue = Cookies.get(key);

            if (!encryptedValue) {
                return null;
            }
            // Decriptazione
            const bytes = CryptoJS.AES.decrypt(
                encryptedValue,
                process.env.REACT_APP_COOKIE_SECRET,
            );

            if (bytes) {
                const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);

                if (decryptedValue) {
                    return decryptedValue;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    // Funzione per cancellare utente
    const deletePatient = async (id) => {
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();

            // Configura la richiesta
            const config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/studio/delete`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                data: {
                    id: id
                },
            };

            // Effettua la richiesta con axios
            const response = await axios.request(config);

            //Controllo la risposta e di conseguenza pubblico la risposta con allert
            // Controllo la risposta
            if (response.data.status === true) {
                setTextGreenAlert("Studio cancellato con successo!");
                setLoadingAlertGreen(true);

                setTimeout(() => {
                    setLoadingAlertGreen(false);
                    setTextGreenAlert("");
                }, 3000);

                //Chiudo il Modale
                setOpen(false);

                //Ricarico la tabella utenti

                getAllConnection();
            } else {
                setTextRedAlert(
                    "Attenzione! Si è verificato un errore nella cancellazione dell'utente.",
                );
                setLoadingAlertRed(true);
                setTimeout(() => {
                    setLoadingAlertRed(false);
                    setTextRedAlert("");
                }, 2000);
            }
        } catch (error) {
            console.log("Errore nella richiesta:", error);
            // Gestione dell"errore, ad esempio aggiornando lo stato per mostrare un messaggio all"utente
        }
    };

    // Fine Cancellazione Utente
    // Recupero le informazioni dai Cookie
    // Esegui il controllo una sola volta quando la pagina viene montata
    useEffect(() => {
        setNome(getDecryptedCookie(process.env.REACT_APP_COOKIE_FNAME));
        setCognome(getDecryptedCookie(process.env.REACT_APP_COOKIE_LNAME));
        setRuolo(getDecryptedCookie(process.env.REACT_APP_COOKIE_ROLE));
        setEmail(getDecryptedCookie(process.env.REACT_APP_COOKIE_EMAIL));
    }, []);

    const handleLogout = () => {
        //Cancello i Cookiee
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_FNAME);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_LNAME);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_ROLE);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_EMAIL);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_USERNAME);

        //Eseguo Logout
        dispatch(logout());
        navigate("/login");
    };

    const handleProfilo = () => {
        navigate("/profilo");
    };

    //Funzione per Generare l"immagine
    function generateInitialsImage(name, surname) {
        const initials = (name[0] || "") + (surname[0] || "");
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        // Imposta le dimensioni del canvas
        const size = 80; // Dimensione dell"immagine
        canvas.width = size;
        canvas.height = size;

        // Imposta il colore di sfondo
        context.fillStyle = "#5930B6"; // Colore di sfondo (puoi personalizzarlo)
        context.fillRect(0, 0, size, size);

        // Imposta le proprietà del testo
        context.fillStyle = "#fff"; // Colore del testo
        context.font = "36px Urbanist, sans-serif"; // Font e dimensione del testo

        context.textAlign = "center";
        context.textBaseline = "middle";

        // Disegna le iniziali al centro del canvas
        context.fillText(initials, size / 2, size / 2);

        // Ritorna l"URL dell"immagine generata
        return canvas.toDataURL("image/png");
    }

    // Genera l"immagine con le iniziali dell"utente
    const initialsImageUrl = generateInitialsImage(nome, cognome);

    const GetAnno = () => {
        return new Date().getFullYear();
    };

    // Richiamo funzione click bottone aggiungi
    const handleClickAdd = () => {
        setData({});
        setEdit(false);
        setOpenForm(true);
    }

    // Richiamo funzione che permette la modifica del cliente
    const handleEdit = (patient) => {

        const fildeBojact = {
            name: patient?.name ?? '',
            street:patient?.address?.street ?? '',
            city:patient?.address?.city ?? '',
            state:patient?.address?.state ?? '',
            postalCode:patient?.address?.postalCode ?? '',
        }
        setData(fildeBojact);
        setIdToUpdate(patient._id);
        setEdit(true);
        setOpenForm(true);
    };
    
    //Richiamo funzione per canccellare utente una volta che utente a dato ok
    const handleDelete = (patient) => {
        setIdToDelete(patient._id);
        setOpen(true);
    };

    const createStudio = async () => {
        // Controllo dei campi obbligatori
        const required_fields = ["name", "street", "city", "state", "postalCode"];
        const address = {...data}
        delete address.name; // Rimuovi la chiave "b"

        // Aggiungi "code" se is_manual è true
        const missing_fields = required_fields.filter(field => !data[field]);
    
        if (missing_fields.length > 0) {
            // Informa l"utente dei campi mancanti
            setTextRedAlert("Errore: compilare tutti i campi obbligatori!");
            setLoadingAlertRed(true);
            setTimeout(() => {
                setLoadingAlertRed(false);
                setTextRedAlert("");
            }, 2000);
            return; // Ferma l"esecuzione della funzione se ci sono campi mancanti
        }
    
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();
    
            // Configura la richiesta
            const config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/studio/create`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                data: {
                    name: data.name,
                    address
                },
            };

            // Effettua la richiesta con axios
            const response = await axios.request(config);
    
            // Controllo la risposta e di conseguenza pubblico la risposta con allert
            if (response.data.status === true) {
                setTextGreenAlert("Studio creato con successo!");
                setLoadingAlertGreen(true);
    
                setTimeout(() => {
                    setLoadingAlertGreen(false);
                    setTextGreenAlert("");
                }, 3000);
    
                // Svuoto lo State
                setData({});
    
                // Chiudo il Modale
                setOpenForm(false);
    
                // Ricarico la tabella utenti
                getAllConnection();
            } else {
                setTextRedAlert("Attenzione! " + response.data.message);
                setLoadingAlertRed(true);
                setTimeout(() => {
                    setLoadingAlertRed(false);
                    setTextRedAlert("");
                }, 2000);
            }
        
        
        } catch (error) {
            if (error.response && error.response.status === 409) {
                const error_message = error.response.data.message || "Errore: conflitto non specificato.";
                setTextRedAlert(error_message);
                setLoadingAlertRed(true);
                setTimeout(() => {
                    setLoadingAlertRed(false);
                    setTextRedAlert("");
                }, 2000);
            } else {
                setTextRedAlert("Errore durante la creazione del Studio!");
                setLoadingAlertRed(true);
                setTimeout(() => {
                    setLoadingAlertRed(false);
                    setTextRedAlert("");
                }, 2000);
            }
        }
    }

    const updatePatient = async () => {
        // Controllo dei campi obbligatori
        const required_fields = ["name", "street", "city", "state", "postalCode"];
        const address = {...data}
        delete address.name; // Rimuovi la chiave "b"
        const missing_fields = required_fields.filter(field => !data[field]);
    
        if (missing_fields.length > 0) {
            // Informa l"utente dei campi mancanti
            setTextRedAlert("Errore: compilare tutti i campi obbligatori!");
            setLoadingAlertRed(true);
            setTimeout(() => {
                setLoadingAlertRed(false);
                setTextRedAlert("");
            }, 2000);
            return; // Ferma l"esecuzione della funzione se ci sono campi mancanti
        }
    
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();
    
            // Configura la richiesta
            const config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/studio/update`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                data: {
                    id: id_to_update,
                    name: data.name,
                    address: address
                },
            };
    
            // Effettua la richiesta con axios
            const response = await axios.request(config);
    
            // Controllo la risposta e di conseguenza pubblico la risposta con allert
            if (response.data.status === true) {
                setTextGreenAlert("Studio aggiornato con successo!");
                setLoadingAlertGreen(true);
    
                setTimeout(() => {
                    setLoadingAlertGreen(false);
                    setTextGreenAlert("");
                }, 3000);
    
                // Svuoto lo State
                // Chiudo il Modale
                setOpenForm(false);
                setTimeout(() => {
                    setData({});
                    setIdToUpdate("");
                    setEdit(false);
                }, 1000);
    
                // Ricarico la tabella utenti
                getAllConnection();
            } else {
                setTextRedAlert("Attenzione! " + response.data.message);
                setLoadingAlertRed(true);
                setTimeout(() => {
                    setLoadingAlertRed(false);
                    setTextRedAlert("");
                }, 2000);
            }
        } catch (error) {
            if(error.response.status === 409) {
                setTextRedAlert("Errore: esiste già un Studio con questo codice fiscale!");
                setLoadingAlertRed(true);
                setTimeout(() => {
                    setLoadingAlertRed(false);
                    setTextRedAlert("");
                }, 2000);
            }
        }
    }

    const handleClose = () => {
        setOpenForm(false);
    }

    const columns = [
        { title: "Nome", 
            formatter: (item) => (<p className="font-semibold">{item.name}</p>) },

        { title: "Indirizzo", 
            formatter: (item) => (
                <span className="font-semibold">
                    {item.address.street}
                </span>
            ) 
        },
        { title: "Città", 
            formatter: (item) => (
                <span className="font-semibold">
                    {item.address.city}
                </span>
            ) 
        },
        { title: "Provincia", 
            formatter: (item) => (
                <span className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">
                    {item.address.state.toUpperCase()}
                </span>
            ) 
        },
        { title: "CAP", 
            formatter: (item) => (
                <span className="font-semibold">
                    {item.address.postalCode}
                </span>
            ) 
        },
        { title: "Azioni", formatter: (item) => (
                <div className="flex items-center">
                    <div className="w-full flex flex-row justify-center items-center">

                        <Tooltip title="Modifica" position="top" arrow={true}>
                            <div className="mx-1">
                                <button
                                    onClick={() => handleEdit(item)}
                                    className="text-yellow-500 hover:text-yellow-600 flex items-center"
                                >
                                    <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </Tooltip>

                        <Tooltip title="Elimina" position="top" arrow={true}>
                            <div className="mx-1">
                                <button
                                    onClick={() => handleDelete(item)}
                                    className="text-red-400 hover:text-red-600 flex items-center"
                                >
                                    <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </Tooltip>

                    </div>
                </div>
            ) 
        },
    ];

    const fields = [
        {
            label: "Nome *",
            name: "name",
            type: "text",
            placeholder: "Mario",
            colSpan: 12
        },
        {
            label: "indirizzo *",
            name: "street",
            type: "text",
            placeholder: "Via Roma n°6",
            colSpan: 3
        },

        {
            label: "Città *",
            name: "city",
            type: "text",
            placeholder: "Saluzzo",
            colSpan: 3
        },
        {
            label: "Provincia *",
            name: "state",
            type: "text",
            placeholder: "TO",
            uppercase: true,
            maxLength: 2,
            colSpan: 3
        },
        {
            label: "CAP *",
            name: "postalCode",
            type: "text",
            placeholder: "10088",
            colSpan: 3
        },
    ];

    const extra_fields = [
        {
            label: "Studio",
            name: "home_office",
            type: "text",
            placeholder: "Studio Dentistico Rossi",
            colSpan: 3
        },
        {
            label: "Rapporto",
            name: "report",
            type: "text",
            placeholder: "In Cura",
            colSpan: 3
        },
        {
            label: "Dottore",
            name: "doctor",
            type: "text",
            placeholder: "ROSSI MF",
            colSpan: 3
        },
        {
            label: "Cassa/Banca",
            name: "bank",
            type: "text",
            placeholder: "Cassa",
            colSpan: 3
        },
        {
            label: "Tipo di pagamento",
            name: "payment",
            type: "text",
            placeholder: "Contanti",
            colSpan: 3
        },
        {
            label: "Conto",
            name: "account",
            type: "text",
            placeholder: "Mario Rossi",
            colSpan: 3
        },
        {
            label: "Mastro",
            name: "mastro",
            type: "text",
            placeholder: "CT",
            uppercase: true,
            maxLength: 2,
            colSpan: 3
        },
        {
            label: "Note",
            name: "notes",
            type: "textarea",
            placeholder: "Note",
            colSpan: 12
        }
    ]

    const grid_variation = {
        1: "lg:col-span-1 col-span-12",
        2: "lg:col-span-2 col-span-12",
        3: "lg:col-span-3 col-span-12",
        4: "lg:col-span-4 col-span-12",
        5: "lg:col-span-5 col-span-12",
        6: "lg:col-span-6 col-span-12",
        7: "lg:col-span-7 col-span-12",
        8: "lg:col-span-8 col-span-12",
        9: "lg:col-span-9 col-span-12",
        10: "lg:col-span-10 col-span-12",
        11: "lg:col-span-11 col-span-12",
        12: "lg:col-span-12 col-span-12"
    };
    
    return (
        <>
            <div className="flex flex-col min-h-screen bg-gray-100">
                <Disclosure as="nav" className="bg-white shadow-sm">
                    <div className="mx-auto lg:max-w-7xl w-full px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                            <div className="flex">
                                <div className="flex flex-shrink-0 items-center">
                                    <img
                                        alt="Your Company"
                                        src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                                        className="block h-8 w-auto lg:hidden"
                                    />
                                    <img
                                        alt="Your Company"
                                        src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                                        className="hidden h-8 w-auto lg:block"
                                    />
                                </div>
                                <MenuNavigazioneDesktop
                                    ruolo={ruolo}
                                    navigate={navigate}
                                />
                            </div>

                            <div className="hidden xl:flex sm:ml-6 sm:items-center">
                                {/* Profile dropdown */}
                                <MenuDesktop
                                    nome={nome}
                                    cognome={cognome}
                                    ruolo={ruolo}
                                    email={email}
                                    initialsImageUrl={initialsImageUrl}
                                    handleLogout={handleLogout}
                                    handleProfilo={handleProfilo}
                                />
                            </div>

                            {/* Mobile Section */}
                            <div className="-mr-2 flex items-center xl:hidden">
                                {/* Mobile menu button */}
                                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    <Bars3Icon
                                        aria-hidden="true"
                                        className="block h-6 w-6 group-data-[open]:hidden"
                                    />
                                    <XMarkIcon
                                        aria-hidden="true"
                                        className="hidden h-6 w-6 group-data-[open]:block"
                                    />
                                </DisclosureButton>
                            </div>
                        </div>
                    </div>

                    {/* Mobile  */}
                    <DisclosurePanel className="xl:hidden">
                        <MenuNavigazioneMobile
                            ruolo={ruolo}
                            navigate={navigate}
                        />
                        <MenuMobile
                            nome={nome}
                            cognome={cognome}
                            ruolo={ruolo}
                            email={email}
                            initialsImageUrl={initialsImageUrl}
                            handleLogout={handleLogout}
                            handleProfilo={handleProfilo}
                        />
                    </DisclosurePanel>
                </Disclosure>

                <div className="flex-grow py-10 ">
                    <header>
                        <div className="lg:mx-20 md:mx-10 mx-6 max-w-full px-4 sm:px-6 lg:px-8 bg-white shadow sm:rounded-lg p-8">
                            <div className="md:grid md:grid-cols-2 flex justify-center md:flex-none items-center">
                                <div>
                                    <h1 className="text-3xl flex justify-center md:flex-none md:justify-start font-bold leading-tight tracking-tight text-gray-900">
                                        Gestione Studi
                                    </h1>
                                    <h3 className="text-base flex justify-center md:flex-none md:justify-start font-semibold leading-7 text-gray-900">
                                        Informazioni
                                    </h3>
                                    <p className="mt-1 flex justify-center md:flex-none md:justify-start max-w-2xl text-sm leading-6 text-gray-500">
                                        Sezione dedicata alla gestione degli Studi.
                                    </p>
                                </div>
                            </div>

                            <div className="mt-2">
                                <div>
                                    <div className="px-4 sm:px-0 "></div>

                                    <div className="w-full">
                                        {loadingAlertGreen ? (
                                            <AlertGreen
                                                testo={textGreenAlert}
                                            />
                                        ) : null}
                                    </div>

                                    {/***/}
                                    <div className="mt-8 flow-root">
                                        <div className="flex md:flex-row flex-col justify-between items-center pb-4">
                                            <div className="flex flex-row items-center">
                                                {/*<h2 className="text-lg leading-6 font-medium text-gray-900">
                                                    Gestione Studi
                                                </h2>*/}
                                                <div className="relative">
                                                    <input 
                                                        type="text"
                                                        className={`ml-2 ${styles.border} bg-white py-1.5 pl-8 pr-4 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm transition`}
                                                        value={search}
                                                        onChange={(e) => setSearch(e.target.value)}
                                                        placeholder="Cerca"
                                                    />
                                                    <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-400">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fillRule="evenodd" d="M12.9 14.32a8 8 0 111.41-1.41l4.3 4.29a1 1 0 01-1.42 1.42l-4.29-4.3zM14 8a6 6 0 11-12 0 6 6 0 0112 0z" clipRule="evenodd" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex flex-row items-center">

                                                <button
                                                    className="flex items-center px-3 py-2 my-6 md:my-0 md:mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    onClick={() => handleClickAdd()}
                                                >
                                                    <PlusCircleIcon
                                                        className="h-5 w-5 mr-2"
                                                        aria-hidden="true"
                                                    />
                                                    Aggiungi
                                                </button>
                                            </div>
                                        </div>
                                        <div className="-mx-4 -my-2 overflow-x-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-400 scrollbar-track-gray-200 sm:-mx-6 lg:-mx-8">
                                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                <Table
                                                    columns={columns} 
                                                    data={patients} 
                                                    loading={loadingPatients}
                                                />
                                            </div>
                                        </div>

                                        {/* Pagination section */}
                                        <div className="py-3 flex items-center justify-between">
                                            <div className="flex-1 flex justify-between sm:hidden">
                                                <button
                                                    onClick={prevPage}
                                                    className={`relative inline-flex items-center px-4 py-2 ${styles.border_previous} text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50`}
                                                >
                                                    Precedente
                                                </button>
                                                <button
                                                    onClick={nextPage}
                                                    className={`ml-3 relative inline-flex items-center px-4 py-2 ${styles.border_next} text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50`}
                                                >
                                                    Seguente
                                                </button>
                                            </div>
                                            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                                <div>
                                                    <p className="text-sm text-gray-700">
                                                        Pagina{" "}
                                                        <span className="font-medium">
                                                            {currentPage}
                                                        </span>{" "}
                                                        di{" "}
                                                        <span className="font-medium">
                                                            {totalPage}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div>
                                                    <nav
                                                        className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                                                        aria-label="Pagination"
                                                    >
                                                        <button
                                                            onClick={prevPage}
                                                            className={`relative inline-flex items-center px-2 py-2 rounded-l-md ${styles.border_previous} bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
                                                        >
                                                            <span>
                                                                Precedente
                                                            </span>
                                                        </button>
                                                        {/* Generate buttons for each page */}
                                                        {Array.from(
                                                            {
                                                                length: totalPage,
                                                            },
                                                            (_, i) => (
                                                                <button
                                                                    key={i + 1}
                                                                    onClick={() =>
                                                                        paginate(
                                                                            i +
                                                                                1,
                                                                        )
                                                                    }
                                                                    className={`relative inline-flex items-center ${styles.border_page} px-4 py-2 text-sm font-medium ${i + 1 === currentPage ? "bg-gray-200" : "bg-white"} hover:bg-gray-50`}
                                                                >
                                                                    {i + 1}
                                                                </button>
                                                            ),
                                                        )}
                                                        <button
                                                            onClick={nextPage}
                                                            className={`relative inline-flex items-center px-2 py-2 rounded-r-md ${styles.border_next} bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
                                                        >
                                                            <span>
                                                                Seguente
                                                            </span>
                                                        </button>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/**/}
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
                {/* Modale di conferma Elimina Utente*/}
                <Dialog open={open} onClose={setOpen} className="relative z-10">
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                    />

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel
                                transition
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                            >
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationTriangleIcon
                                            aria-hidden="true"
                                            className="h-6 w-6 text-red-600"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <DialogTitle
                                            as="h3"
                                            className="text-base font-semibold leading-6 text-gray-900"
                                        >
                                            Cancella Studio
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Sei sicuro di voler cancellare
                                                il Studio selezionato?
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            deletePatient(id_to_delete)
                                        }
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                    >
                                        Elimina
                                    </button>
                                    <button
                                        type="button"
                                        data-autofocus
                                        onClick={() => setOpen(false)}
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    >
                                        Annulla
                                    </button>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </Dialog>

                {/* Modale per Aggiunta e Modifica Studio */}

                <Dialog
                    open={openForm}
                    onClose={setOpenForm}
                    className="relative z-10"
                >
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel
                                transition
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 sm:max-w-7xl"
                                ref={modal_ref}
                            >
                                <div className="sm:flex">

                                    <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <div className="flex flex-row items-center">
                                            <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
                                                edit ? "bg-yellow-100" : "bg-green-100"
                                            } sm:mx-0 sm:h-10 sm:w-10`}>
                                                {
                                                    edit ?
                                                    <PencilSquareIcon
                                                        aria-hidden="true"
                                                        className="h-6 w-6 text-yellow-700"
                                                    /> :
                                                    <PlusCircleIcon
                                                        aria-hidden="true"
                                                        className="h-6 w-6 text-green-600"
                                                    />
                                                }
                                            </div>
                                            <div className="flex flex-col items-start ml-4">
                                                <DialogTitle
                                                    as="h3"
                                                    className="text-base font-semibold leading-6 text-gray-900"
                                                >
                                                    {
                                                        edit ? 
                                                        "Modifica Studio" :
                                                        "Crea Nuovo Studio"
                                                    }
                                                </DialogTitle>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        {
                                                            edit ?
                                                            "Inserisci i seguenti campi e clicca su Modifica" :
                                                            "Inserisci i seguenti campi e clicca su Crea"
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-full">
                                            {loadingAlertRed ? (
                                                <AlertRed
                                                    testo={textRedAlert}
                                                />
                                            ) : null}
                                        </div>

                                        <div className="mt-6 w-full isolate -space-y-px rounded-md">
                                            <div className="grid grid-cols-12 row gap-4">
                                                {fields.map((field, index) => {
                                                    if (field.type === "radio") {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className={`${grid_variation[field.colSpan]} relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-green-600`}
                                                            >
                                                                <label className="block text-xs font-medium text-gray-900">
                                                                    {field.label}
                                                                </label>
                                                                <div className="flex flex-row justify-start items-center space-x-4 mt-1">
                                                                    {field.options.map((option, i) => (
                                                                        <div key={i} className="flex items-center">
                                                                            <input
                                                                                onChange={(e) =>
                                                                                    handleChangeData(
                                                                                        e.target.value,
                                                                                        field.name,
                                                                                    )
                                                                                }
                                                                                type="radio"
                                                                                id={option.value}
                                                                                name={field.name}
                                                                                value={option.value}
                                                                                checked={
                                                                                    data?.[field.name] === option.value
                                                                                }
                                                                                className="mr-1 cursor-pointer"
                                                                            />
                                                                            <label
                                                                                htmlFor={option.value}
                                                                                className="text-sm text-gray-700 cursor-pointer"
                                                                            >
                                                                                {option.label}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        );
                                                    }

                                                    if(field.type === "date") {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className={`${grid_variation[field.colSpan]} relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-green-600`}
                                                            >
                                                                <label className="block text-xs font-medium text-gray-900">
                                                                    {field.label}
                                                                </label>
                                                                <input
                                                                    onChange={(e) => handleChangeData(e, field.name)}
                                                                    value={moment(data?.[field.name] || "").format("YYYY-MM-DD")}
                                                                    id={field.name}
                                                                    name={field.name}
                                                                    type={field.type}
                                                                    placeholder={field.placeholder}
                                                                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                
                                                    if(field.type === "textarea") {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className={`${grid_variation[field.colSpan]} relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-green-600`}
                                                            >
                                                                <label className="block text-xs font-medium text-gray-900">
                                                                    {field.label}
                                                                </label>
                                                                <textarea
                                                                    onChange={(e) => handleChangeData(e, field.name)}
                                                                    value={data?.[field.name] || ""}
                                                                    id={field.name}
                                                                    name={field.name}
                                                                    type={field.type}
                                                                    placeholder={field.placeholder}
                                                                    className="block w-full h-24 border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>
                                                        );
                                                    }

                                                    if(field.type === "code" && !edit) {
                                                        return (
                                                            <div className={`${grid_variation[field.colSpan]} relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-green-600`}>
                                                                <div className="flex flex-row justify-start items-center">
                                                                    <label
                                                                        htmlFor="payment"
                                                                        className="block text-xs font-medium text-gray-900"
                                                                    >
                                                                        {field.label}
                                                                    </label>
                                                                    <div className="flex flex-row justify-start items-center text-sm font-medium text-center text-gray-500 ml-2 my-2">
                                                                        <div className="me-2">
                                                                            <button
                                                                                className={`inline-block px-2 py-1 rounded-lg cursor-pointer ${
                                                                                    data && !data.is_manual
                                                                                        ? "text-white bg-blue-600 border-blue-600 border-2"
                                                                                        : "hover:bg-blue-100 text-blue-600 border-blue-600 border-2"
                                                                                }`}
                                                                                onClick={() => handleChangeData("automatic", "type_code")}
                                                                            >
                                                                                Automatico
                                                                            </button>
                                                                        </div>
                                                                        <div className="me-2">
                                                                            <button
                                                                                className={`inline-block px-2 py-1 rounded-lg cursor-pointer ${
                                                                                    data && data.is_manual
                                                                                        ? "text-white bg-blue-600 border-blue-600 border-2"
                                                                                        : "hover:bg-blue-100 text-blue-600 border-blue-600 border-2"
                                                                                }`}
                                                                                onClick={() => handleChangeData("manual", "type_code")}
                                                                            >
                                                                                Manuale
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    {data && data.is_manual && (
                                                                        <input
                                                                            onChange={(e) => handleChangeData(e, "code")}
                                                                            value={data?.code || ""}
                                                                            id="code"
                                                                            name="code"
                                                                            type="text"
                                                                            placeholder={field.placeholder}
                                                                            className="block w-auto border-0 rounded-md rounded-t-none p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 uppercase"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    }

                                                    if(
                                                        field.type === "text" ||
                                                        field.type === "email"
                                                    ) {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className={`${grid_variation[field.colSpan]} relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-green-600`}
                                                            >
                                                                <label className="block text-xs font-medium text-gray-900">
                                                                    {field.label}
                                                                </label>
                                                                <input
                                                                    onChange={(e) => handleChangeData(e, field.name)}
                                                                    value={data?.[field.name] || ""}
                                                                    id={field.name}
                                                                    name={field.name}
                                                                    type={field.type}
                                                                    placeholder={field.placeholder}
                                                                    maxLength={field.maxLength ? field.maxLength : null}
                                                                    className={`block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${field.uppercase ? "uppercase" : ""}`}
                                                                />
                                                            </div>
                                                        );
                                                    }

                                                    return null;
                                                })}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        onClick={() => edit ? updatePatient() : createStudio()}
                                        type="button"
                                        className={
                                            edit ?
                                            "inline-flex w-full justify-center rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 sm:ml-3 sm:w-auto" :
                                            "inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                                        }
                                    >
                                        { edit ? "Modifica" : "Crea" }
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleClose()}
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    >
                                        Annulla
                                    </button>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </Dialog>

                <ModalCode
                    code={code}
                    open={openModalCode}
                    setOpen={setOpenModalCode}
                />

                <footer className="bg-white">
                    <div className="mx-auto px-6 sm:py-3 md:py-3 xl:py-5 items-center">
                        <div className="mt-4 md:order-1 md:mt-0">
                            <p className="text-center text-xs leading-5 text-gray-500">
                                {GetAnno()} © Centro del Sorriso, sviluppato
                                con il ❤️ da Tribus Informatica Srl
                            </p>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default Studi;