import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
} from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

const ModalImage = ({ src, data, setSrc, patient ,   open, setOpen }) => {

    const [currentImage, setCurrentImage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    // Funzione per spostarsi tra le immagini
    const navigateImages = (direction, satus) => {
        data.forEach((element) => {
            const url = element.url;
            element.src_images.forEach((e, index) => {
                if (`${url}/${e.path}` === src) {
                    const newIndex = index + direction;

                    // Blocca navigazione se si raggiunge la prima o l'ultima immagine
                    if (newIndex >= 0 && newIndex < element.src_images.length) {
                        setCurrentImage(index + direction + 1)
                        setIsLoading(true);
                        setSrc(`${url}/${element.src_images[newIndex].path}`);
                    }
                }
            });
        });
    };

    const handleShortcut = () => {
        console.log("Control + S pressed!");
        // Aggiungi qui la logica che vuoi eseguire
    };
    const handleShortcutx = () => {
        console.log("Control + x pressed!");
        // Aggiungi qui la logica che vuoi eseguire
    };


    // Aggiungi gestione tasti della tastiera
    useEffect(() => {

        const handleKeydown = (event) => {
            if (event.key === "ArrowLeft") {
                navigateImages(-1); // Freccia sinistra
            } else if (event.key === "ArrowRight") {
                navigateImages(1); // Freccia destra
            } else if (event.key === "Escape") {
                setOpen(false); // Chiudi la modale con ESC
            }
        };
        if (open) {
            window.addEventListener("keydown", handleKeydown);
        }
        return () => {
            window.removeEventListener("keydown", handleKeydown);
        };
    }, [open, src, data]);



    return (
        <Dialog open={open} onClose={setOpen} className="relative z-10">
            <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center text-center sm:p-0">
                    <DialogPanel className="relative transform  ">
                        <div className="relative   w-full h-full grid grid-cols-[auto_1fr_auto] items-center">

                            {/* Pulsante sinistro */}
                            <button
                                onClick={() => navigateImages(-1)} // Navigazione indietro
                                className={` mr-10 w-16 h-16 flex justify-center item-center bg-blue-100 p-3 rounded-full text-sm font-semibold col-span-1 ${
                                    data.some((element) => {
                                        const url = element.url;
                                        return element.src_images[0] &&
                                            `${url}/${element.src_images[0].path}` === src;
                                    })
                                        ? "opacity-50 cursor-not-allowed"
                                        : ""
                                }`}
                                disabled={data.some((element) => {
                                    const url = element.url;
                                    return element.src_images[0] &&
                                        `${url}/${element.src_images[0].path}` === src;
                                })}
                            >

                                    <ChevronLeftIcon  aria-hidden="true" className=" w-full h-full text-[#c315a2] " />

                            </button>


                            {/* Immagine */}
                            <div className="relative flex flex-col   justify-center items-center">

                            <div className="w-full bg-indigo-100 bg-opacity-80  px-2.5 py-2 my-2  rounded-lg">
                                <p className="font-semibold text-indigo-700 text-2xl">
                                    {patient.name} {patient.surname}
                                </p>
                                <p className="italic">
                                    Immagine{" "}
                                    <span className="text-indigo-700 font-semibold italic">{currentImage}</span> di{" "}
                                    <span className="text-indigo-700 font-semibold italic">{patient.src_images.length}</span>
                                </p>
                            </div>

                                <div className="relative w-auto h-auto max-w-full max-h-[70vh] overflow-hidden rounded-lg shadow-xl">
                                    {isLoading && (
                                        <div className="absolute inset-0 flex justify-center items-center bg-gray-200 bg-opacity-75 animate-pulse">
                                            <span className="text-gray-500">Caricamento...</span>
                                        </div>
                                    )}
                                    <img
                                        src={src}
                                        alt={"Imagne paziente"}
                                        loading="lazy"
                                        className={`w-auto h-auto  max-w-full max-h-[70vh] overflow-hidden rounded-lg shadow-xl object-contain image-loaded ${
                                            isLoading ? 'opacity-0' : 'opacity-100'
                                        } transition-opacity duration-300`}
                                        onLoad={() => setIsLoading(false)}
                                    />
                                </div>

                            </div>


                            {/* Pulsante destro */}
                            <button
                                onClick={() => navigateImages(1)} // Navigazione avanti
                                className={`ml-10 w-16 h-16 flex justify-center item-center bg-blue-100 p-3 rounded-full text-sm font-semibold col-span-1 ${
                                    data.some((element) => {
                                        const url = element.url;
                                        return element.src_images[element.src_images.length - 1] &&
                                            `${url}/${element.src_images[element.src_images.length - 1].path}` === src;
                                    })
                                        ? "opacity-50 cursor-not-allowed"
                                        : ""
                                }`}
                                disabled={data.some((element) => {
                                    const url = element.url;
                                    return element.src_images[element.src_images.length - 1] &&
                                        `${url}/${element.src_images[element.src_images.length - 1].path}` === src;
                                })}
                            >
                                <ChevronRightIcon aria-hidden="true" className=" w-full h-full text-[#c315a2] "  />
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
};

export default ModalImage;
