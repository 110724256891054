import { useCallback } from "react";
import { AutoScroller, getResponsiveStyle, getStaticStyle } from "muuri-react";

let uuid = 3;
// Generate n items.
export function generateItems(n) {
  const items = [];
  for (let i = 0; i < n; i++) {
    const color = oneOf(["orange", "green", "blue"]);
    const key = `${uuid++}`;

    items.push({ key, color });
  }

  return items;
}


// Return the 'onSend' method.
export function useSend(setItems, setOnSendData) {
  return useCallback(
    ({ key, fromId, toId }) => {

      let updatedItemsCopy = []
      setItems(items => {
        // Trova l'oggetto `from` e rimuovi l'elemento da spostare
        const updatedItems = items.map(item => {
          if (item.id === fromId) {
            return {
              ...item,
              items: item.items.filter(innerItem => innerItem.id !== key)
            };
          }
          if (item.id === toId) {
            // Trova l'elemento da spostare dall'oggetto `from`
            const itemToMove = items
              .find(item => item.id === fromId)
              .items.find(innerItem => innerItem.id === key);
              
            // Aggiungi l'elemento all'oggetto `to`
            return {
              ...item,
              items: [...item.items, itemToMove]
            };
          }
          return item;
        });

        updatedItemsCopy = updatedItems

        return updatedItems;
      });

      setOnSendData({
        updatedItems: updatedItemsCopy,
        key,
        fromId,
        toId,
        status: true,
      });
    },
    [setItems]
  );
}

// Get the common layout options of the MuuriComponents.
export function getOptions(elementRef, secondaryRef, action , scrollContainerRef , startDrag) {
    return {
      groupIds: ["container"],
      dragSort: { groupId: "container" },
      dragHandle: ".content-header",
      dragEnabled: startDrag,
      dragContainer: scrollContainerRef,
      dragAutoScroll: {
        targets: [
          {
            element: scrollContainerRef,
            axis: "y", // Usa una stringa per specificare l'asse
          },
          {
            element: scrollContainerRef,
            axis: "y", // Specifica l'asse come stringa
          },
        ],
      },
      dragSortHeuristics: {
        sortInterval: 0,
      },
      dragSortPredicate: {
        action,
      },
    };
  }
  

// Return the dimensions of the item.
// This is done to allow us to use relative dimensions (e.g. "width: 50%")
// in the items and fix them during the drag (e.g. "width: 60px").

{/*
    export function getDimensions(grid, id, isDragging) {
      // The Muuri component is virtually divided into 8 columns,
      // the width of the item will be 3 columns minus the margin.
      const columns = id === "column" ? 1 / 1 : 1 / 3;
      // The margin of the item, can be any CSS values
      // valid for the margin expressed in "px" or "%".
      const margin = id === "column" ? "10%" : "1.8%";
      // The width/height ratio. If you want to set a static
      // height just set it in the style and leave the "ratio" option empty.
      const ratio = id === "column" ? 3 : 1.4;
    
      // If the item is dragging the style
      // must not be relative to the parent.
      return !isDragging
        ? getResponsiveStyle({ columns, margin, ratio })
        : getStaticStyle({ grid, columns, margin, ratio });
    }
    
*/}


export function getDimensions(grid, id, isDragging) {
    // Impostiamo 5 colonne per riga, ogni item occuperà 1/5 della larghezza totale.
    const columns = 1 / 6;  // 5 item per riga
    // La distanza (margine) tra gli item
    const margin = "1.5%"; // margine tra gli item
    // Il rapporto larghezza/altezza per l'item (per esempio, 1.4 per un layout più alto)
    const ratio = 1.4;
  
    // Se l'item è in fase di drag, il suo stile sarà calcolato separatamente
    return !isDragging
      ? getResponsiveStyle({ columns, margin, ratio })  // Calcola lo stile reattivo
      : getStaticStyle({ grid, columns, margin, ratio });  // Calcola lo stile statico durante il drag
  }

// Return one of the values of the array.
export function oneOf(array) {
  return array[Math.floor(Math.random() * Math.floor(array.length))];
}
