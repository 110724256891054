// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.styles_border__Idt-x {
    border: 1px solid lightgray;
}

.styles_border_previous__mUFpF {
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

.styles_border_page__Eg6PT {
    border: 1px solid lightgray;
}

.styles_border_next__WumZK {
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}`, "",{"version":3,"sources":["webpack://./src/pages/styles.module.css"],"names":[],"mappings":";AACA;IACI,2BAA2B;AAC/B;;AAEA;IACI,gCAAgC;IAChC,iCAAiC;IACjC,+BAA+B;IAC/B,kCAAkC;AACtC;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,gCAAgC;IAChC,iCAAiC;IACjC,+BAA+B;IAC/B,kCAAkC;AACtC","sourcesContent":["\n.border {\n    border: 1px solid lightgray;\n}\n\n.border_previous {\n    border-left: 1px solid lightgray;\n    border-right: 1px solid lightgray;\n    border-top: 1px solid lightgray;\n    border-bottom: 1px solid lightgray;\n}\n\n.border_page {\n    border: 1px solid lightgray;\n}\n\n.border_next {\n    border-left: 1px solid lightgray;\n    border-right: 1px solid lightgray;\n    border-top: 1px solid lightgray;\n    border-bottom: 1px solid lightgray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border": `styles_border__Idt-x`,
	"border_previous": `styles_border_previous__mUFpF`,
	"border_page": `styles_border_page__Eg6PT`,
	"border_next": `styles_border_next__WumZK`
};
export default ___CSS_LOADER_EXPORT___;
