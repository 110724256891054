import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { InboxIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import Tesseract from "tesseract.js";
import { Buffer } from "buffer";
import { io } from "socket.io-client";
import socketClient from "socket.io-client";

import { henadAiType, predict } from "../componets/AiComponent/index";
import StepWizard from "../componets/StepWizard";

import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";

import moment from "moment";
import Table from "../componets/Table";


//Importo Alert
import { AlertRed, AlertGreen } from "../componets/Alert";


import {
    PlusCircleIcon,
    PencilSquareIcon,
    TrashIcon,
    WindowIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    ArrowUpTrayIcon,
} from "@heroicons/react/20/solid";

import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Papa from 'papaparse';
import ImportBox from "../componets/ImportBox"
// CSS
import styles from "./styles.module.css";

const DragAndDrop = ({ setIsUploadeCsv , studios , studioSelectOptions , handleExport , getAuthToken , getAllConnection }) => {

    const [active_step, setActiveStep] = useState(0);
    const [file, setFile] = useState(null);
    const [AllData, setAllData] = useState([]);
    const [AllDataCopy, setAllDataCopy] = useState([]);
    const [dataToShow, setDataToShow] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [loadingData, setLoadingData] = useState(false);
    const [studioId, setStudioId] = useState('');
    const [allertMassage, setAllertMassage] = useState('');
    const [sucessMassage, setSucessMassage] = useState('');
    const [loadingCaricamentoPazienti, setLoadingCaricamentoPazienti] = useState(false);



    const generateUniqueStrings = (length, count) => {
        const uniqueStrings = new Set();
    
        while (uniqueStrings.size < count) {
            const randomString = generateRandomString(length);
            uniqueStrings.add(randomString);
        }
    
        return Array.from(uniqueStrings);
    };
    
    const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
    
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters[randomIndex];
        }
    
        return result;
    };
    
    const henadalSelectChange = (e)=>{
        setStudioId(e)
    }

    const showAllert = (massage)=>{
        setAllertMassage(massage)
        setTimeout(()=>{
            setAllertMassage('')
        },5000)
    }
    const showSucess = (massage)=>{
        setSucessMassage(massage)
        setTimeout(()=>{
            setSucessMassage('')
        },5000)
    }

    const heandelGoBehinde = ()=>{
        if(active_step === 0){
            setIsUploadeCsv(false)
        }else 
        if(active_step === 1){
            setAllData([])
            setActiveStep(0)
        } else if(active_step === 2) {
            setAllData(AllDataCopy)
            setActiveStep(1)
        }
    }

    const heandelGoForward = ()=>{

        if(active_step === 0){
            if(!studioId){
                showAllert('Seleziona uno studio prima di andare avanti')
            }else 
            if(!file){
                showAllert('Carica un file prima di andare avanti')
            } else {
                handleChange(file)
            }
        }else 
        if(active_step === 1){
            setAllDataCopy(AllData);
            setAllData(prev => prev.filter((item)=>{return item.approved}))
            setActiveStep(2)
        } else if(active_step === 2) {
            createPatient();
        }

    }

    // Funzione per impaginare i dati
    const paginateAndSearch = (data, itemsPerPage, currentPage, query) => {
        // Funzione di ricerca
        const search = (data, query) => {
            const queryLowerCase = query.toString().toLowerCase();
            return data.filter(item => {
                // Controlla se il valore dell'input è presente in uno qualsiasi dei valori delle chiavi dell'oggetto
                return Object.values(item).some(value =>
                    value && value.toString().toLowerCase().includes(queryLowerCase)
                );
            });
        };
    
        // Applicare il filtro ai dati
        const filteredData = search(data, query);

    
        // Calcolare le pagine
        const totalPages = Math.ceil(filteredData.length / itemsPerPage);
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
    
        // Determinare i dati da mostrare
        const dataToShow = filteredData.slice(startIndex, endIndex);
    
        // Aggiornare lo stato
        setTotalPages(totalPages);
        setDataToShow(dataToShow);
        setLoadingData(true);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    // Esempio di navigazione pagina
    const nextPage = () =>
        setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
    const prevPage = () =>
        setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));

    // Funzione per gestire l"upload delle immagini
    const handleChange = async (file) => {

        try {
            if (!file) throw new Error("File non fornito");

            // Controllo il tipo di file
            const fileType = file.type;

            if (fileType === "text/csv") {
                const Data = await parseCSV(file);
                setAllData(Data);
                setActiveStep(1);
                console.log(Data)
            } else if (
                fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                fileType === "application/vnd.ms-excel"
            ) {
                const Data = await parseExcel(file);
                setAllData(Data);
                setActiveStep(1);
                console.log(Data)
            } else {
                throw new Error("Tipo di file non supportato. Carica un file CSV o Excel.");
            }
        } catch (error) {
            console.error("Errore durante la lettura del file:", error);
            return [];
        }
    };

{/**
    const validateObject = (obbject, key) => {

        const invalidKeys = [];
        const upd_data = { ...data };

        if(key === "Sesso") {
            upd_data[key] = e;
        } else if(key === "Data di nascita") {
            const formatted_birthdate = moment(e.target.value).format();
            upd_data[key] = formatted_birthdate;
        } else if(key === "Telefono/Cellulare") {
            const phone_number = e.target.value;
            // Regex per controllare un numero di telefono italiano
            const phone_regex =  /^[+0-9]*$/;
    
            if (phone_regex.test(phone_number)) {
                upd_data[key] = phone_number; // Salva solo se il numero è valido
            }
        } else {
            upd_data[key] = key !== "Email" ? 
                capitalizeFirstLetter(e.target.value) :
                e.target.value;
        }
        
    }


    */}

    // Funzione per il parsing di file CSV
    const parseCSV = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const csvData = event.target.result;

                // Utilizzo della libreria PapaParse
                Papa.parse(csvData, {
                    header: true, // Ritorna un array di oggetti basato sull'intestazione
                    skipEmptyLines: true,
                    complete: (results) => {
                        const uniqueStrings = generateUniqueStrings(10, results.data.length)
                        const dataMaped = results.data.map((item, index)=>{
                            return {...item, approved: true, id: uniqueStrings[index]}
                        })
                        resolve(dataMaped);
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsText(file);
        });
    };

    // Funzione per il parsing di file Excel
    const parseExcel = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: "array" });

                // Consideriamo solo il primo foglio
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                // Converte il foglio in JSON
                const jsonData = XLSX.utils.sheet_to_json(sheet, { defval: null });
                const uniqueStrings = generateUniqueStrings(10, jsonData.length)
                const dataMaped = jsonData.map((item, index)=>{
                    return {...item, approved: true, id: uniqueStrings[index]}
                })
                resolve(dataMaped);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsArrayBuffer(file);
        });
    };

    // Funzione per modificare le chavi del oggetto in modo
    function transformPatientsData(inputArray) {
        return inputArray.map(patient => ({
            name: patient.Nome,
            surname: patient.Cognome,
            gender: patient.Sesso,
            email: patient.Email,
            birthdate: patient['Data di nascita'],
            birthplace: patient['Luogo di nascita'],
            phone: patient['Telefono/Cellulare'],
            province: patient.Provincia || '', // Gestisce un campo opzionale
            home_office: studioId, 
            is_manual: patient.IsManuale || false, // Gestisce un campo opzionale
            code: patient.Codice || '' // Gestisce un campo opzionale
        }));
    }

    function chunkArray(array, chunkSize) {
        if (!Array.isArray(array)) {
            throw new TypeError("Input must be an array");
        }
        if (typeof chunkSize !== "number" || chunkSize <= 0) {
            throw new TypeError("Chunk size must be a positive number");
        }
    
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    }

    const createPatient = async () => {
        setLoadingCaricamentoPazienti(true)
        // Controllo dei campi obbligatori
        const dataTransformed = transformPatientsData(AllData);

        const data = {
            patients:  dataTransformed,
        };

        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();
    
            // Configura la richiesta
            const config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/patients/create-multiple`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                data: data,
            };
    
            // Effettua la richiesta con axios
            const response = await axios.request(config);
            // Controllo la risposta e di conseguenza pubblico la risposta con allert
            if (response.data.status === true) {
                getAllConnection();
                setIsUploadeCsv(false);
                setAllData([])
                setActiveStep(0)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const file_types = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];


    const isExcelFile = (file) => {
        if (!file) {
            console.error("Nessun file fornito");
            return false;
        }
    
        // Ottieni l'estensione del file
        const fileName = file.name.toLowerCase();
        const isXlsx = fileName.endsWith(".xlsx");

        if(isXlsx){
            return isXlsx;
        } else {
            showAllert('file caricato deve essere in formato xlsx')
        }
    
    };

    const renderUploader = () => (
        <div className="w-full h-72 flex justify-center items-center border-2 border-dashed border-gray-300 rounded-md">
            <ImportBox file={file} setFile={setFile} handleFileUpload={isExcelFile} titale={'Cliccare o trascinare in quest\'area per caricare il file'} />
        </div>
    );

    const renderLoading = (message) => (
        <div className="w-full flex flex-col justify-center items-center">
            <div className="w-full flex flex-row justify-center items-center py-4">
                <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
            </div>
            <p className="text-lg font-semibold text-gray-700 mt-2">{message}</p>
        </div>
    );

    function getStudioNameById(id) {
        // Trova l'oggetto con l'_id corrispondente
        const foundObject = studios.find(item => item._id === id);
        // Restituisci il nome se trovato, altrimenti null
        return foundObject ? foundObject.name : null;
    }

    const handleApprovalChange = (item, isChecked) => {

        setAllData(prev => prev.map((previtem)=>{
            if(previtem.id === item.id){
                return {...previtem, approved:!previtem.approved}
            }else{
                return previtem
            }
        }))

    };

    let columns = []

    if(active_step === 2){
         columns = [

            { title: "Nome e Cognome", formatter: (item) => <p className="font-semibold">{item.Nome} {item.Cognome}</p> },
            { title: "Sesso", formatter: (item) => (item.Sesso === "female" ? "Donna" : "Uomo") },
            { 
                title: "Studio", 
                formatter: (item) => (
                    <p >
                        {getStudioNameById(studioId)}
                    </p>
                ) 
            },
            { title: "Luogo di nascita", formatter: (item) => `${item['Luogo di nascita']}` },
            { title: "Data di nascita", formatter: (item) => moment(item['Data di nascita']).format("DD/MM/YYYY") },
            { title: "Telefono/Cellulare", formatter: (item) => item['Telefono/Cellulare'] || "-" },
            { title: "Email", formatter: (item) => item.Email || "-"},
        ];
    } else {
        
         columns = [
            { 
                title: "Approva", 
                formatter: (item) => (
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-indigo-600 border-gray-300 rounded "
                            checked={item.approved}
                            onChange={(e) => handleApprovalChange(item, e.target.checked)}
                        />
                    </div>
                )
            },
            { title: "Nome e Cognome", formatter: (item) => <p className="font-semibold">{item.Nome} {item.Cognome}</p> },
            { title: "Sesso", formatter: (item) => (item.Sesso === "female" ? "Donna" : "Uomo") },
            { 
                title: "Studio", 
                formatter: (item) => (
                    <p >
                        {getStudioNameById(studioId)}
                    </p>
                ) 
            },
            { title: "Luogo di nascita", formatter: (item) => `${item['Luogo di nascita']}` },
            { title: "Data di nascita", formatter: (item) => moment(item['Data di nascita']).format("DD/MM/YYYY") },
            { title: "Telefono/Cellulare", formatter: (item) => item['Telefono/Cellulare'] || "-" },
            { title: "Email", formatter: (item) => item.Email || "-"},
        ];

    }


    useEffect(()=>{
        setLoadingData(false);
        paginateAndSearch(AllData, itemsPerPage, currentPage , searchInput)
    }, [AllData,  currentPage, itemsPerPage, searchInput])

    return (
        <div className="flex-grow py-10">
            <header>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                        Carica pazienti
                    </h1>
                </div>
            </header>
            <header>
                <div className="mx-auto max-w-7xl py-8 xl:px-56 md:px-40 px-10">
                    <StepWizard
                        steps={[
                            "Caricamento file",
                            "Selezione import",
                            "Riepilogo finale"
                        ]}
                        active_step={active_step}
                    />
                </div>
                <div className="mt-4 xl:px-56 md:px-40 px-10">

                    {active_step === 0 ?
                        <div className="flex mx-auto shadow mb-2 max-w-full items-center px-2.5 py-6 rounded-lg text-sm font-medium bg-green-100 text-green-800 relative">
                            <button
                                className="absolute inset-0  flex items-center justify-center w-full h-full bg-transparent text-green-800 font-medium rounded-lg"
                                onClick={() => handleExport()}
                            >
                                <ArrowUpTrayIcon
                                    className="h-5 w-5 mr-2"
                                    aria-hidden="true"
                                />
                                Scarica il file di esempio
                            </button>
                        </div>
                    : null}


                    {dataToShow.length > 0 || active_step === 1
                    ?
                        <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8 bg-white shadow sm:rounded-lg p-6">
                            <div className="mt-8 flow-root">
                                <div className="flex justify-between items-center pb-4">
                                    <div className="flex flex-row items-center">
                                        {/*<h2 className="text-lg leading-6 font-medium text-gray-900">
                                            Gestione Studi
                                        </h2>*/}
                                        <div className="relative">
                                            <input 
                                                type="text"
                                                className={`ml-2 ${styles.border} bg-white py-1.5 pl-8 pr-4 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm transition`}
                                                value={searchInput}
                                                onChange={(e) => setSearchInput(e.target.value)}
                                                placeholder="Cerca"
                                            />
                                            <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M12.9 14.32a8 8 0 111.41-1.41l4.3 4.29a1 1 0 01-1.42 1.42l-4.29-4.3zM14 8a6 6 0 11-12 0 6 6 0 0112 0z" clipRule="evenodd" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div className="-mx-4 -my-2 overflow-x-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-400 scrollbar-track-gray-200 sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                            <Table
                                                columns={columns} 
                                                data={dataToShow} 
                                                loading={loadingData}
                                            />
                                        </div>
                                </div>
                                {/* Pagination section */}
                                <div className="py-3 flex items-center justify-between">
                                    <div className="flex-1 flex justify-between sm:hidden">
                                        <button
                                            onClick={prevPage}
                                            className={`relative inline-flex items-center px-4 py-2 ${styles.border_previous} text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50`}
                                        >
                                            Precedente
                                        </button>
                                        <button
                                            onClick={nextPage}
                                            className={`ml-3 relative inline-flex items-center px-4 py-2 ${styles.border_next} text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50`}
                                        >
                                            Seguente
                                        </button>
                                    </div>
                                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                        <div>
                                            <p className="text-sm text-gray-700">
                                                Pagina{" "}
                                                <span className="font-medium">
                                                    {currentPage}
                                                </span>{" "}
                                                di{" "}
                                                <span className="font-medium">
                                                    {totalPages}
                                                </span>
                                            </p>
                                        </div>
                                        <div>
                                            <nav
                                                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                                                aria-label="Pagination"
                                            >
                                                <button
                                                    onClick={prevPage}
                                                    className={`relative inline-flex items-center px-2 py-2 rounded-l-md ${styles.border_previous} bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
                                                >
                                                    <span>
                                                        Precedente
                                                    </span>
                                                </button>
                                                {/* Generate buttons for each page */}
                                                {Array.from(
                                                    {
                                                        length: totalPages,
                                                    },
                                                    (_, i) => (
                                                        <button
                                                            key={i + 1}
                                                            onClick={() =>
                                                                paginate(
                                                                    i +
                                                                        1,
                                                                )
                                                            }
                                                            className={`relative inline-flex items-center ${styles.border_page} px-4 py-2 text-sm font-medium ${i + 1 === currentPage ? "bg-gray-200" : "bg-white"} hover:bg-gray-50`}
                                                        >
                                                            {i + 1}
                                                        </button>
                                                    ),
                                                )}
                                                <button
                                                    onClick={nextPage}
                                                    className={`relative inline-flex items-center px-2 py-2 rounded-r-md ${styles.border_next} bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
                                                >
                                                    <span>
                                                        Seguente
                                                    </span>
                                                </button>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    : loadingCaricamentoPazienti ?
                        renderLoading('Caricamento pazienti')
                    :
                        <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8 bg-white shadow sm:rounded-lg p-6">
                        {
                            allertMassage ?

                            <AlertRed testo={allertMassage}/>

                            : null
                        }
                        {
                            sucessMassage ?

                            <AlertGreen testo={sucessMassage}/>

                            : null
                        }

                        <div className="mb-4">
                            <label htmlFor="location" className="block text-sm/6 font-medium text-gray-900">
                                Seleziona lo studio
                            </label>
                            <div className="mt-2 relative">
                                <select
                                    id="location"
                                    name="location"
                                    value={studioId}
                                    onChange={(e) => henadalSelectChange(e.target.value)}
                                    className="w-full relative overflow-hidden appearance-none bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 rounded-md sm:text-sm/6"
                                >
                                    <option value="" disabled className="text-gray-400 bg-red p-2">
                                        Seleziona un'opzione
                                    </option>
                                    {studioSelectOptions.map((option, idx) => (
                                        <option 
                                            key={idx} 
                                            value={option.value} 
                                            className="text-gray-900 text-sm bg-white hover:bg-gray-100"
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>


                            {/* Stato iniziale: uploader visibile */}
                            {renderUploader()}
                        </div>
                    }



                    <div className="w-full flex flex-row justify-center items-center mt-5">
                        <button
                            className="flex items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            onClick={() => heandelGoBehinde()}
                        >
                            Torna indietro
                        </button>
                        <button
                            className="flex items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => heandelGoForward()}
                        >
                            Avanti
                        </button>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default DragAndDrop;
