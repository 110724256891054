import React, { useEffect, useState } from "react";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from "@headlessui/react";
import {
    CheckIcon,
    ChevronUpDownIcon,
    XCircleIcon,
    EyeIcon,
    HomeIcon,
} from "@heroicons/react/20/solid";

import { useLocation } from "react-router-dom"; // Ottieni la location dal router

import navigazioneData from "./Navigazione"; // Importa l'array di navigazione

function MenuNavigazioneDesktop({ ruolo, navigate }) {
    const [navigazioneFiltrata, setNavigazioneFiltrata] = useState([]); // Stato per memorizzare l'array filtrato
    const location = useLocation(); // Ottieni la posizione attuale (URL)

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    // Funzione per filtrare l'array in base ai permessi
    function filtraNavigazionePerPermessi(permesso) {
        if (permesso === "admin") {
            return navigazioneData; // Se il ruolo è 'admin', ritorna tutto l'array
        }
        return navigazioneData.filter((item) => item.permessi === permesso); // Filtra l'array in base al permesso fornito
    }

    useEffect(() => {
        // Filtra l'array in base al ruolo
        const navigazioneFiltrata = filtraNavigazionePerPermessi(ruolo).map(
            (item) => {
                // Imposta `current` su true solo se il nome della pagina corrisponde alla route corrente
                return {
                    ...item,
                    current: location.pathname.includes(
                        item.name.toLowerCase(),
                    ),
                };
            },
        );

        // Aggiorna lo stato con l'array filtrato e modificato
        setNavigazioneFiltrata(navigazioneFiltrata);
    }, [ruolo, location.pathname]); // Effettua l'aggiornamento quando il ruolo o il percorso cambiano

    return (
        <div className="hidden xl:flex xl:w-full sm:-my-px sm:ml-6 sm:space-x-8">
            {navigazioneFiltrata.map((item) => {
                // Ottieni il componente dell'icona per ogni voce
                const Icon = item.icon;

                return (
                    <button
                        key={item.name}
                        onClick={() => navigate(`/${item.href}`)}
                        aria-current={item.current ? "page" : undefined}
                        className={classNames(
                            item.current
                                ? "border-indigo-500 text-gray-900" // Classe per l'item attivo
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium",
                        )}
                    >
                        {Icon && (
                            <Icon className="h-5 w-5 pr-1" aria-hidden="true" />
                        )}{" "}
                        {/* Mostra l'icona */}
                        {item.name}
                    </button>
                );
            })}
        </div>
    );
}

export { MenuNavigazioneDesktop };
