'use client'

import { useEffect, useState } from 'react'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import DragAndDropComponentNoGid from "./DragAndDropComponentNoGrig";
import { ChevronDownIcon } from '@heroicons/react/20/solid';



const users = [
  { id: 1, name: 'Alice Johnson', email: 'alice@example.com' },
  { id: 2, name: 'Bob Smith', email: 'bob@example.com' },
  { id: 3, name: 'Charlie Brown', email: 'charlie@example.com' },
  // Altri utenti...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export default function UserSidebar({patients , settings , Selectedpatient, setSelectedpatient, getStudioNameById , getAllConnection}) {

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [SelectedId, setSelectedId] = useState(patients[0]._id);

  //Funzione per Generare l"immagine
  function generateInitialsImage(name, surname) {
      const initials = (name[0] || "") + (surname[0] || "");
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      // Imposta le dimensioni del canvas
      const size = 80; // Dimensione dell"immagine
      canvas.width = size;
      canvas.height = size;

      // Imposta il colore di sfondo
      context.fillStyle = "#5930B6"; // Colore di sfondo (puoi personalizzarlo)
      context.fillRect(0, 0, size, size);

      // Imposta le proprietà del testo
      context.fillStyle = "#fff"; // Colore del testo
      context.font = "36px Urbanist, sans-serif"; // Font e dimensione del testo

      context.textAlign = "center";
      context.textBaseline = "middle";

      // Disegna le iniziali al centro del canvas
      context.fillText(initials, size / 2, size / 2);

      // Ritorna l"URL dell"immagine generata
      return canvas.toDataURL("image/png");
  }

  useEffect(()=>{
        const Selectedpatient = patients.find((patient)=>patient._id === SelectedId);
        setSelectedpatient(Selectedpatient)
  },[SelectedId, patients])

  return (
    <div className=" h-[60vh] flex" >

      {/* Sidebar per desktop */}
      <div className="flex w-64 flex-col  text-white ">
        <nav>
          <ul role="list" className="divide-y divide-white/5">
            {patients.map((patient) => (
              <li key={patient._id} className={`px-2 py-2 sm:px-6 ${SelectedId === patient._id ? "bg-indigo-500/20" : ''} `}
              onClick={()=> setSelectedId(patient._id)}
              >
                <div className="flex items-center gap-x-3 justify-between">
                  {/* Sinistra: Contenuto principale */}
                  <div className="flex items-center gap-x-3">
                    <img
                      alt=""
                      src={generateInitialsImage(patient.name, patient.surname)}
                      className="size-6 flex-none rounded-full bg-gray-800"
                    />
                    <h3 className="flex-auto truncate text-sm/6 font-semibold text-gray-600">
                      {patient.name}
                    </h3>
                  </div>

                  {/* Destra: Icona */}
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                </div>

                <p className="mt-3 truncate text-sm text-gray-500 ">
                  Studio <span className="text-gray-400">{getStudioNameById(patient.home_office)}</span>
                </p>
              </li>

            ))}
          </ul>
        </nav>
      </div>

      {/* Contenuto principale */}
      <div className="flex-1 bg-gray-100">


        {/*
        <DragAndDropComponent  Selectedpatient={Selectedpatient} settings={settings}/>
        */} 
        <DragAndDropComponentNoGid Selectedpatient={Selectedpatient} settings={settings} patients={patients} getAllConnection={getAllConnection} />

        
        

      </div>
    </div>
  )
}
