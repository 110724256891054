import * as React from "react";

const SvgIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Capa_1"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 513.078 513.078"
  >
    <path d="M409.934 440.26c-2 1.56-4.38 2.31-6.73 2.31-3.28 0-6.53-1.46-8.7-4.26l-131.14-169.13-129.82 170.13a10.97 10.97 0 0 1-8.75 4.33c-2.33 0-4.68-.74-6.67-2.26-4.83-3.68-5.75-10.59-2.07-15.42l133.36-174.77-134.71-173.74c-3.72-4.81-2.84-11.72 1.96-15.44s11.71-2.85 15.43 1.96l131.13 169.12 129.83-170.13c3.69-4.83 10.59-5.75 15.42-2.07 4.83 3.69 5.75 10.59 2.07 15.42l-133.37 174.77 134.72 173.75c3.72 4.8 2.84 11.71-1.96 15.43"></path>
  </svg>
);

export default SvgIcon;
